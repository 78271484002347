import { FileImageFilled, UploadOutlined } from "@ant-design/icons";
import { Button, Space, Tag, Typography, Upload } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
export const FormFileUpload = ({
  name,
  control,
  maxCount = 1,
  multiple = false,
  label,
  rules,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    field: { ref, ...fd },
    fieldState,
  } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        ".anticon": {
          color: "#000 !important",
        },
      }}
    >
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <Upload
        multiple={multiple}
        {...props}
        {...fd}
        {...fieldState}
        maxCount={maxCount}
        beforeUpload={(e) => {
          fd.onChange(e);
          return false;
        }}
      >
        <Button
          ref={ref}
          style={fieldState?.error ? { borderColor: "red", color: "red" } : {}}
          icon={<UploadOutlined />}
        >
          {t("inner-fcc.upload")}
        </Button>
      </Upload>
      {typeof fd.value === "string" && (
        <a
          href={fd.value}
          target="_blank"
          style={{ color: "#036323", fontWeight: "500" }}
          className="file-link"
          rel="noreferrer"
        >
          <Space style={{ gap: "4px", padding: "0 4px" }}>
            <FileImageFilled /> {t("seeFile")}
          </Space>
        </a>
      )}
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
