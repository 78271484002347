import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../../../components/formComponents/input";
import { useMyform } from "./useMyform";
import { package_volume_type_options } from "../../../../constants";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { Manufacturer } from "../manufacturer";
import { FormFileUpload } from "../../../../../../components/formComponents/upload";
import { FormToggle } from "../../../../../../components/formComponents/toggle/toggle";
export const PreparationTwoForms = ({ myRef }) => {
  const {
    props,
    isRegistered,
    lang_props,
    preparationTypes,
    preparationTypesLoading,
    isEdit,
  } = useMyform();
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <FormInput
              {...lang_props}
              label={t("mineral.package_volume")}
              placeholder={t("mineral.package_volume")}
              name="product.package_volume"
            />
          </Col>
          <Col span={9}>
            <FormSelect
              {...props}
              label={t("mineral.package_volume_type")}
              placeholder={t("inner-fcc.tanla")}
              name="product.package_volume_type"
              options={package_volume_type_options}
            />
          </Col>
        </Row>
      </Col>

      <Manufacturer />
      <Col span={24} md={12}>
        <FormSelect
          {...props}
          label={t("mineral.productType")}
          placeholder={t("inner-fcc.tanla")}
          name="product.product_type"
          options={preparationTypes}
          loading={preparationTypesLoading}
        />
      </Col>

      {!isRegistered && (
        <>
          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              rules={undefined}
              label={t("mineral.agreeLetter")}
              name="product.man_consent_letter"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              label={t("mineral.certificate")}
              name="product.man_certificate"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              rules={undefined}
              label={t("mineral.certificate_translated")}
              name="product.man_certificate_translated"
            />
          </Col>
          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              rules={undefined}
              label={t("mineral.license")}
              name="product.license"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              label={t("mineral.techPass")}
              name="product.tech_passport"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              rules={undefined}
              label={t("mineral.labSum")}
              name="product.lab_conclusion"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              label={t("mineral.sertifikat_sifat")}
              name="product.quality_certificate"
            />
          </Col>

          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              label={t("mineral.quti_yorliq")}
              name="product.package_label"
            />
          </Col>
        </>
      )}

      <Col span={24} md={12}>
        <FormFileUpload
          {...props}
          label={t(
            isRegistered
              ? "mineral.new infoDrugChemical min close"
              : "mineral.infoDrugChemical"
          )}
          name="product.file"
        />
      </Col>

      {/* {isRegistered && (
        <Col span={24} md={12}>
          <FormFileUpload
            {...props}
            label={t("mineral.uploadDoc min 6a")}
            name="product.chemical_info"
          />
        </Col>
      )} */}

      <Col span={24}>
        <div style={{ textAlign: "center", padding: "16px 0" }}>
          <FormToggle
            disabled={isEdit}
            styleWrapper={{ alignItems: "center", justifyContent: "center" }}
            name="application.is_reregister"
            defaultChecked={isRegistered}
            myref={myRef}
            label={t("mineral.reregister")}
          />
        </div>
      </Col>

      {isRegistered && (
        <>
          <Col span={24} md={12}>
            <FormInput
              {...props}
              min={0}
              type="number"
              label={t("mineral.oldCertNum")}
              placeholder={t("mineral.oldCertNum")}
              name="application.old_cert_number"
            />
          </Col>
          <Col span={24} md={12}>
            <FormFileUpload
              {...props}
              label={t("mineral.oldCert")}
              name="application.old_certificate"
            />
          </Col>
        </>
      )}
    </Row>
  );
};
