export const package_volume_type_options = [
  { value: "MG", label: "mg" },
  { value: "GR", label: "gr" },
  { value: "KG", label: "kg" },
  { value: "ML", label: "ml" },
  { value: "L", label: "l" },
];

export const valid_after_open_units_uz = [
  // { value: "MINUTE", label: "minut" },
  { value: "HOUR", label: "Soat" },
  { value: "DAY", label: "Kun" },
  { value: "WEEK", label: "Hafta" },
  { value: "MONTH", label: "Oy" },
  { value: "YEAR", label: "Yil" },
];

export const valid_after_open_units_ru = [
  // { value: "MINUTE", label: "минут" },
  { value: "HOUR", label: "Час" },
  { value: "DAY", label: "День" },
  { value: "WEEK", label: "Неделя" },
  { value: "MONTH", label: "Месяц" },
  { value: "YEAR", label: "Год" },
];
