import "./login.css";
import logoImg from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
// import { Redirect } from "react-router-dom";
import Login from "../login/Login";
import queryString from "query-string";
import Loader from "../loader/Loader";
import { requestReg, requestRegAuth } from "../../helpers/createRequest";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { language } from "../../redux/actions/langAction";
import { links } from "../../redux/actions/linksAction";
import Select from "react-select";

const SelectLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 46,
      minHeight: 46,
    }),
  };

  const { link } = useSelector((state) => state.link);
  const changeLink = (name) => {
    dispatch(links(name));
  };

  // const queryParams = queryString.parse(window.location.search);
  // let code = queryParams.code;

  const { lang: lan } = useSelector((state) => state.language);

  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [info, setInfo] = useState("");
  const [content, setContent] = useState(null);

  const [countries, setCountries] = useState([]);

  const getCountry = () => {
    requestRegAuth.get("item/countries").then((res) => {
      if (res.data.success) {
        setCountries(
          res?.data?.data
            ?.filter((item) => item?.id !== 237)
            .map((c) => {
              return {
                value: c.id,
                label: c.name_ru,
              };
            })
        );
      }
    });
  };

  useEffect(() => {
    getCountry();
  }, []);
  // useEffect(() => {
  //   returnPage();

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500);

  // }, []);

  // const returnPage = () => {
  //   console.log(citizen);
  //   if (citizen || code) {
  //     // setIsLoading(false);
  //     //   console.log(citizen);
  //     setContent(<Login />);
  //   } else {
  //     return;
  //   }
  // };

  //   let initialalContent = (
  //     <div className="select-login">
  //       <div className="wrapper-card">
  //         <div className="card-content-wrap">
  //           <div className="logo-content">
  //             <img src={logoImg} />
  //             <p>{t("login.agency")}</p>
  //           </div>
  //           <div className="options-wrap">
  //             <label>
  //               <input
  //                 type="radio"
  //                 name="radio"
  //                 onChange={(e) => setCitizen(e.target.checked)}
  //               />
  //               <span>{t("login.citizenUz")}</span>
  //             </label>
  //             <label>
  //               <input
  //                 type="radio"
  //                 name="radio"
  //                 onChange={(e) => setForeigner(e.target.checked)}
  //               />
  //               <span>{t("login.foreign")}</span>
  //             </label>
  //           </div>
  //           <div className="btn-wrapper">
  //             <button type="button" className="login-btn" onClick={returnPage}>
  //               {t("login.continue")}
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  const onSubmitHandle = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const data = {
      first_name: firstname,
      last_name: lastname,
      middle_name: middlename,
      phone: phone,
      company_name: company,
      position: position,
      country: country?.value,
      address: address,
      email: email,
      password: password,
      info: info,
    };

    requestRegAuth
      .post("user/foreign/register", data)
      .then((res) => {
        if (res.data.success) {
          toast.success(t("login.registerSuccess"));
          toast.warning(t("login.activate"));
          setTimeout(() => {
            window.location.replace("/login");
            setIsLoading(false);
          }, 4000);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.password) {
          toast.error(`Password: ${e?.response?.data?.password[0]}`);
        }
        if (e?.response?.data?.email) {
          toast.error(`Email: ${e?.response?.data?.email[0]}`);
        }
        toast.error(`${t("login.registerError")}`);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !content ? (
        <div className="select-login">
          <div className="wrapper-card">
            <div className="card-content-wrap">
              <div className="langs-outer">
                <div className="langs" style={{ maxWidth: "80px" }}>
                  {/* <div
                  className={lan === "uz" ? "active-lan" : ""}
                  onClick={() => changeLan("uz")}
                >
                  UZ
                </div> */}
                  <div
                    className={lan === "ru" ? "active-lan" : ""}
                    onClick={() => changeLan("ru")}
                  >
                    RU
                  </div>
                  <div
                    className={lan === "en" ? "active-lan" : ""}
                    onClick={() => changeLan("uz")}
                  >
                    UZ
                  </div>
                </div>
              </div>
              <div className="logo-content">
                <img src={logoImg} alt="" />
                <p style={{ fontWeight: "500" }}>{t("navbar.logo")}</p>
              </div>
              <h2 style={{ textAlign: "center", marginTop: "15px" }}>
                {t("login.registration")}
              </h2>
              <form onSubmit={onSubmitHandle}>
                <div className="roow">
                  <div>
                    <label>{t("login.firstname")}</label>
                    <input
                      type="text"
                      required
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>{t("login.lastname")}</label>
                    <input
                      type="text"
                      required
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="roow">
                  <div>
                    <label>{t("login.middlename")}</label>
                    <input
                      type="text"
                      value={middlename}
                      onChange={(e) => setMiddlename(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>{t("login.phone")}</label>
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="roow">
                  <div>
                    <label>{t("login.company")}</label>
                    <input
                      type="text"
                      required
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>{t("login.position")}</label>
                    <input
                      type="text"
                      required
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                    />
                  </div>
                </div>
                <div className="roow">
                  <div>
                    <label>{t("login.country")}</label>
                    {/* <select
                      type="text"
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="" disabled>
                        {t("inner-fcc.tanla")}
                      </option>
                      {countries?.map((country, idx) => {
                        return (
                          <option value={country.id} key={idx} style={{ color: 'white !important' }}>
                            {country[`name_${lan}`]}
                          </option>
                        );
                      })}
                    </select> */}
                    <div>
                      <Select
                        onChange={(e) => setCountry(e)}
                        required
                        value={country}
                        options={countries}
                        placeholder={t("inner-fcc.tanla")}
                        size="small"
                        className="react-custom-select"
                      />
                    </div>
                  </div>
                  <div>
                    <label>{t("login.address")}</label>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="roow">
                  <div>
                    <label>{t("login.email")}</label>
                    <input
                      type="text"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>{t("login.password")}</label>
                    <input
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="roow">
                  <div className="row-full">
                    <label>{t("login.info")}</label>
                    <textarea
                      rows={3}
                      value={info}
                      onChange={(e) => setInfo(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div>
                  <Link
                    to="/login"
                    className="sign-link"
                    // onClick={() => changeLink("/foreign/login")}
                  >
                    {t("login.signin")}
                  </Link>
                </div>
                <div className="btn-wrapper">
                  <button
                    type="submit"
                    className={
                      !isLoading ? "login-btn button-active" : "login-btn"
                    }
                    disabled={isLoading}
                  >
                    {t("login.submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        content
      )}
    </>
  );
};

export default SelectLogin;
