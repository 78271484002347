import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./components/layout/Layout";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";

import "./App.css";
import Login from "./components/login/Login";
import { API_URL, BASE_URL } from "./constants/variables";
import ItemContext from "./store/item-context";
import SelectLogin from "./components/signup/SelectLogin";
import { ConfigProvider } from "antd";
// import "./pages/chemicals/main.css";

export const baseURL = API_URL;
const App = () => {
  // console.log(useSelector((state) => state));
  const itemContext = useContext(ItemContext);

  const { token } = useSelector((state) => state.login);

  const [menu, setMenu] = useState(true);

  // const [actData, setActData] = useState({});

  useEffect(() => {
    if (window.innerWidth < 992) {
      setMenu(false);
    } else {
      setMenu(true);
    }
    // console.log(window.location.search.substring(1));
  }, [window.innerWidth]);

  // useEffect(() => {
  //   if (token == newToken) {
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log(token);
  // });

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#036323',
          },
        }}
      >
        <>
          <ToastContainer />
          <Switch>
            {/* <Route path="/efito/act-invoice">
            <ActComparison />
          </Route>
          <Route path="/lab/import-sample">
            <LabSample />
          </Route>
          <Route path="/efito/import-fss/print">
            <ExportFss />
          </Route>
          {token && (
            <Route path="/act-invoice">
              <Comparison />
            </Route>
          )} */}
            {token && (
              <>
                <Navbar menu={menu} setMenu={setMenu} />
                <Sidebar menu={menu} setMenu={setMenu} />
                <Layout baseURL={baseURL} menu={menu} setMenu={setMenu} />
              </>
            )}
            {!token && (
              <Route path="/login" exact component={Login} />
              //   <Login />
              // </Route>
            )}
            {!token && (
              <Route path="/signup" exact component={SelectLogin} />
              // <SelectLogin />
              // </Route>
            )}
            {!token && <Route path="*" component={Login} />}
          </Switch>
        </>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
