import { Col, Divider, Row } from "antd";
import { CompanyForms } from "../company/forms";
import { PreparationForms } from "./components/preparation/forms";
import { PreparationTwoForms } from "./components/preparationTwo/forms";

export const Mineral = ({ myRef }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <CompanyForms />
      </Col>
      <Divider />
      <Col span={24}>
        <PreparationForms />
      </Col>
      <Col span={24}>
        <PreparationTwoForms myRef={myRef} />
      </Col>
    </Row>
  );
};
