import { Input, Space, Typography } from "antd";
import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
const { TextArea } = Input;
const { Text } = Typography;
export const FormTextArea = ({
  name,
  control,
  rows = 3,
  label,
  rules,
  ...props
}) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    name,
    control,
    rules: rules,
  });
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {label && (
        <label>
          {label}
          {rules?.required && (
            <Text type="danger">
              <sup> *</sup>
            </Text>
          )}
        </label>
      )}
      <TextArea
        status={fieldState?.error && "error"}
        rows={rows}
        {...props}
        {...field}
        {...fieldState}
      />
      {fieldState?.error?.message && (
        <Text type="danger">{t(fieldState.error.message)}</Text>
      )}
    </Space>
  );
};
