import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./modals.css";
import { useSelector } from "react-redux";
import { requestReg } from "../../../helpers/createRequest";
import { makeOptions } from "../../../utils";
import {
  package_volume_type_options,
  valid_after_open_units_ru,
  valid_after_open_units_uz,
} from "../constants";
import { toast } from "react-toastify";
import { IoMdAdd } from "react-icons/io";
import AddSubstanceModal from "./addSubstanceModal";
import ManufacturersModal from "./manufacturersModal";
import AddManModal from "./AddManModal";
import Marquee from "react-fast-marquee";

const ChemicalModal = (props) => {
  const { isOpenChemical, setIsOpenChemical, appData, setAppData, getList } =
    props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const lang = useSelector((state) => state.language.lang);
  const { pinOrTin, phone, email } = useSelector((state) => state.login);

  const { director } = JSON.parse(localStorage.getItem("applicant") || "{}");

  const [isOpenLangModal, setIsOpenLangModal] = useState(true);
  const [langType, setLangType] = useState("UZ");

  const [regex, setRegex] = useState();
  // /^[a-zA-Z0-9!@#$&()%^&+_[|/?=.,"'{}`* -\]]+$/
  // /^[ЁёА-я0-9!@#$&()%^&+_[|/?=.,"'{}`* -\]]+$/
  useEffect(() => {
    if (langType === "UZ") {
      setRegex(/^[^\u0400-\u04FF]+$/);
    } else if (langType === "RU") {
      setRegex(/^[ЁёА-я0-9!@#$&()%^&+_[|/?=.,"°'{}`* -\]]+$/);
    } else {
      setRegex(null);
    }
  }, [langType]);

  const [isEdit, setIsEdit] = useState(false);
  const [openAddSubstanseModal, setOpenAddSubstanseModal] = useState(false);
  const [isModalOpenManufacturers, setIsModalOpenManufacturers] =
    useState(false);
  const [addManModal, setAddManModal] = useState(false);

  const [valid_after_open_unit, setvalid_after_open_unit] = useState("MONTH");
  const [valid_period_unit, setvalid_period_unit] = useState("MONTH");

  const [isReregister, setIsReregister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [substances, setSubstances] = useState([]);
  const [crops, setCrops] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [preparatTypes, setPreparatTypes] = useState([]);
  const [canceledDrugView, setCanceledDrugView] = useState(null);

  const [appFile, setAppFile] = useState(null);
  const [appFileView, setAppFileView] = useState(null);

  const [companyReq, setCompanyReq] = useState(null);
  const [companyReqView, setCompanyReqView] = useState(null);

  const [agreeLetter, setAgreeLetter] = useState(null);
  const [agreeLetterView, setAgreeLetterView] = useState(null);

  const [certificate, setCertificate] = useState(null);
  const [certificateView, setCertificateView] = useState(null);

  const [certificateTranslated, setCertificateTranslated] = useState(null);
  const [certificateTranslatedView, setCertificateTranslatedView] =
    useState(null);

  const [license, setLicense] = useState(null);
  const [licenseView, setLicenseView] = useState(null);

  const [productFile, setProductFile] = useState(null);
  const [productFileView, setProductFileView] = useState(null);

  const [oldCertFile, setOldCertFile] = useState(null);

  const cancel = () => {
    setAppData({});
    form.resetFields();
    setIsOpenChemical(false);
    setAgreeLetterView(null);
    setCertificateView(null);
    setCertificateTranslatedView(null);
    setLicenseView(null);
    setProductFileView(null);
    setCanceledDrugView(null);
  };

  const getSubstances = async () => {
    await requestReg
      .get(`product/substances`)
      .then((res) => {
        if (res?.data?.success) {
          setSubstances(
            res?.data?.data?.map((item) => {
              return {
                value: item.id,
                label: `${item.substance} / ${item.company}`,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCrops = async () => {
    await requestReg
      .get(`item/crops`)
      .then((res) => {
        if (res?.data?.success) {
          setCrops(res?.data?.data?.filter((item) => item.id !== 83));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShapes = async () => {
    await requestReg
      .get(`item/shapes`)
      .then((res) => {
        setShapes(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManufacturers = async () => {
    await requestReg
      .get(`product/manufacturers`, {
        params: {
          page: 1,
          page_size: 20,
          search: searchTerm?.length >= 4 ? searchTerm : null,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          setManufacturers(res.data.data.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTypes = async () => {
    await requestReg
      .get(`product/types`, {
        params: {
          type: "chemical",
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          setPreparatTypes(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let formDataToSend = new FormData();
  const appendFormData = (text, value) => {
    if (value) {
      formDataToSend.append(text, value);
    }
  };

  const submitForm = async (values) => {
    if (isReregister || isEdit) {
      setIsLoading(true);

      appendFormData("application.language", langType);

      // OLD CERTIFICATE
      if (isReregister) {
        appendFormData("application.old_cert_number", values?.old_cert_number);
        appendFormData("application.old_certificate", oldCertFile);
      }
      // APPLICATION
      appendFormData("application.comment", values?.comments);
      appendFormData("application.app_type", "CHEM");
      appendFormData("application.is_reregister", isReregister);
      appendFormData("application.file", appFile);

      // COMPANY
      appendFormData("company.inn", values?.inn);
      appendFormData("company.phone", values?.phone);
      appendFormData("company.email", values?.email);
      appendFormData("company.owner_fullname", values?.owner_fullname);
      appendFormData("company.post_index", values?.post_index);
      appendFormData("company.post_address", values?.post_address);
      appendFormData("company.fax", values?.fax);
      appendFormData("company.requisites", companyReq);

      // PRODUCT FILES
      if (!isReregister) {
        appendFormData("product.man_consent_letter", agreeLetter);
        appendFormData("product.man_certificate", certificate);
        appendFormData(
          "product.man_certificate_translated",
          certificateTranslated
        );
        appendFormData("product.license", license);
      }
      appendFormData("product.file", productFile);

      // PRODUCT DATAS
      appendFormData("product.name", values?.name);
      appendFormData("product.generic_name", values?.generic_name);
      appendFormData("product.substances", values?.substances);
      appendFormData("product.for_crop", values?.for_crop);
      appendFormData("product.product_shape_id", values?.product_shape);
      appendFormData("product.spend_rate", values?.spend_rate);
      appendFormData("product.concentration", values?.concentration);
      appendFormData("product.harmful_org", values?.harmful_org);
      appendFormData("product.use_method", values?.use_method);
      appendFormData("product.use_period", values?.use_period);
      appendFormData("product.use_repeat", values?.use_repeat);
      appendFormData("product.valid_after_open", values?.valid_after_open);
      appendFormData("product.valid_after_open_unit", valid_after_open_unit);
      appendFormData("product.valid_period", values?.valid_period);
      appendFormData("product.valid_period_unit", valid_period_unit);
      appendFormData("product.before_harvest_day", values?.before_harvest_day);
      appendFormData("product.valid_saving_info", values?.valid_saving_info);
      appendFormData("product.package_volume", values?.package_volume);
      appendFormData(
        "product.package_volume_type",
        values?.package_volume_type
      );
      appendFormData("product.manufacturer_id", values?.manufacturer);
      appendFormData("product.product_type_id", values?.product_type);
      try {
        if (isEdit) {
          appendFormData("company.company_id", appData?.company?.id);
          appendFormData("application.application_id", appData?.id);
          appendFormData(
            "product.product_id",
            appData?.product_register_apps[0]?.id
          );

          await requestReg.patch(`product/application/create`, formDataToSend, {
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
          });
        } else {
          await requestReg.post(`product/application/create`, formDataToSend, {
            headers: {
              "Content-Type":
                "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
            },
          });
        }
        toast.success("Muvaffaqqiyatli yuborildi!");
        setIsOpenChemical(false);
        form.resetFields();
        getList();
      } catch (err) {
        // toast.error("Xatolik sodir bo'ldi")
        const errors = Object.values(err?.response?.data);
        errors?.forEach((item) => {
          Object.entries(item)?.forEach((i) => {
            toast.error(
              <div>
                <p>{`${i["0"]} => ${i["1"][0]}`}</p>
              </div>,
              {
                autoClose: false,
              }
            );
            // console.log(`${i['0']} => ${i['1'][0]}`)
          });
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.info(
        lang === "uz"
          ? "Bu yil hisobidan Kimyoviy preparatlarga yangi ariza berish muddati tugagan."
          : "С этого года истек срок подачи новых заявок на Химические препараты."
      );
    }
  };

  useEffect(() => {
    form.setFieldValue("owner_fullname", director);
    form.setFieldValue("phone", phone);
    form.setFieldValue("email", email);
    form.setFieldValue("inn", pinOrTin);
    form.setFieldValue("isReregister", isReregister);
  }, [director, form, phone, email, pinOrTin, isReregister, appData]);

  useEffect(() => {
    if (appData) {
      form.setFieldValue("post_index", appData?.company?.post_index);
      form.setFieldValue("fax", appData?.company?.fax);
      form.setFieldValue("comments", appData?.comment);
      form.setFieldValue("post_address", appData?.company?.post_address);

      setCompanyReqView(appData?.company?.requisites);
      setAppFileView(appData?.file);
      setCanceledDrugView(appData?.cancel_file);
    }
    if (appData?.product_register_apps?.length) {
      form.setFieldValue("name", appData?.product_register_apps[0].name);
      form.setFieldValue(
        "generic_name",
        appData?.product_register_apps[0].generic_name
      );
      form.setFieldValue(
        "substances",
        appData?.product_register_apps[0].substances?.map((i) => i.id)
      );
      form.setFieldValue(
        "for_crop",
        appData?.product_register_apps[0].for_crop?.map((i) => i.id)
      );
      form.setFieldValue(
        "product_shape",
        appData?.product_register_apps[0].product_shape?.id
      );
      form.setFieldValue(
        "spend_rate",
        appData?.product_register_apps[0].spend_rate
      );
      form.setFieldValue(
        "concentration",
        appData?.product_register_apps[0].concentration
      );
      form.setFieldValue(
        "harmful_org",
        appData?.product_register_apps[0].harmful_org
      );
      form.setFieldValue(
        "use_method",
        appData?.product_register_apps[0].use_method
      );
      form.setFieldValue(
        "use_period",
        appData?.product_register_apps[0].use_period
      );
      form.setFieldValue(
        "use_repeat",
        appData?.product_register_apps[0].use_repeat
      );
      form.setFieldValue(
        "valid_after_open",
        appData?.product_register_apps[0].valid_after_open
      );
      form.setFieldValue(
        "valid_period",
        appData?.product_register_apps[0].valid_period
      );
      form.setFieldValue(
        "before_harvest_day",
        appData?.product_register_apps[0].before_harvest_day
      );
      form.setFieldValue(
        "valid_saving_info",
        appData?.product_register_apps[0].valid_saving_info
      );
      form.setFieldValue(
        "package_volume",
        appData?.product_register_apps[0].package_volume
      );
      form.setFieldValue(
        "package_volume_type",
        appData?.product_register_apps[0].package_volume_type
      );
      form.setFieldValue(
        "manufacturer",
        appData?.product_register_apps[0].manufacturer?.id
      );
      form.setFieldValue(
        "product_type",
        appData?.product_register_apps[0].product_type?.id
      );
      setAgreeLetterView(appData?.product_register_apps[0].man_consent_letter);
      setCertificateView(appData?.product_register_apps[0].man_certificate);
      setCertificateTranslatedView(
        appData?.product_register_apps[0].man_certificate_translated
      );
      setLicenseView(appData?.product_register_apps[0].license);
      setProductFileView(appData?.product_register_apps[0].file);
      setvalid_period_unit(appData?.product_register_apps[0].valid_period_unit);
      setvalid_after_open_unit(
        appData?.product_register_apps[0].valid_after_open_unit
      );
    }
  }, [appData, form]);

  useEffect(() => {
    getSubstances();
    getCrops();
    getShapes();
    getTypes();
  }, []);

  useEffect(() => {
    getManufacturers();

    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    if (Object.keys(appData).length) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [appData]);

  useEffect(() => {
    setIsOpenLangModal(true);
  }, [appData]);

  return (
    <>
      <AddSubstanceModal
        open={openAddSubstanseModal}
        setOpen={setOpenAddSubstanseModal}
        getSubstances={getSubstances}
      />

      <ManufacturersModal
        isModalOpenManufacturers={isModalOpenManufacturers}
        setIsModalOpenManufacturers={setIsModalOpenManufacturers}
        setAddManModal={setAddManModal}
        getManufacturers={getManufacturers}
      />

      <AddManModal open={addManModal} setOpen={setAddManModal} />

      <Modal
        open={isOpenChemical}
        footer={[]}
        width={1200}
        closeIcon={null}
        className="guvohnoma-modal"
        centered
        onCancel={() => setIsOpenChemical(false)}
      >
        <Modal
          open={isOpenLangModal}
          footer={[]}
          closeIcon={[]}
          width={350}
          title={
            lang === "uz"
              ? "Ariza to’ldiriladigan tilni tanlang"
              : "Выберите язык, на котором будет заполнена заявка"
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              style={{ padding: "0 30px" }}
              onClick={() => {
                setIsOpenLangModal(false);
                setLangType("UZ");
              }}
            >
              O’zbekcha
            </Button>
            <Button
              style={{ padding: "0 30px" }}
              onClick={() => {
                setIsOpenLangModal(false);
                setLangType("RU");
              }}
            >
              Русский
            </Button>
          </div>
        </Modal>
        <Marquee gradient={false} pauseOnHover={true} speed={100}>
          <p style={{ color: "red" }}>
            {lang === "uz"
              ? "Faqat qayta ro’yxatdan o’tkazish uchun arizalar qabul qilinadi"
              : "Принимаются только заявки на перерегистрацию"}
          </p>
        </Marquee>
        <h1>{t("navbar.chemicals")}</h1>
        {appData?.file && (
          <div className="modal-inner warning">
            <div className="warning-wrap common">
              <p>{appData?.cancel_reason}</p>
              {canceledDrugView && (
                <a
                  href={canceledDrugView}
                  target="_blank"
                  style={{ color: "#036323", fontWeight: "500" }}
                  className="file-link"
                  rel="noreferrer"
                >
                  {t("seeFile")}
                </a>
              )}
            </div>
          </div>
        )}
        <Form
          name="chemicals-form"
          form={form}
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{ maxWidth: "none" }}
          initialValues={{
            remember: true,
          }}
          onFinish={submitForm}
          autoComplete="off"
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col lg={12} xs={24}>
              <Form.Item
                label={t("lab.applicant")}
                name="owner_fullname"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("inner-fcc.kontakt")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("applicantEmail")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("compPin")}
                name="inn"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label={`* ${t("mineral.uploadDoc")}`} name="doc">
                {appFileView ? (
                  <a
                    href={appFileView}
                    target="_blank"
                    style={{ color: "#036323", fontWeight: "500" }}
                    className="file-link"
                    rel="noreferrer"
                  >
                    {t("seeFile")}
                  </a>
                ) : null}
                <input
                  type="file"
                  name="doc"
                  className="file-input"
                  onChange={(e) => setAppFile(e.target.files[0])}
                  required={!isEdit}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.zipcode")}
                name="post_index"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input
                  size="large"
                  type="number"
                  placeholder="0"
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.mailAddress")}
                name="post_address"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" type="text" disabled={isEdit} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label={t("mineral.fax")} name="fax">
                <Input
                  size="large"
                  type="number"
                  placeholder="0"
                  disabled={isEdit}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item label={`* ${t("mineral.rekvizit")}`} name="requisites">
                {companyReqView ? (
                  <a
                    href={companyReqView}
                    target="_blank"
                    style={{ color: "#036323", fontWeight: "500" }}
                    className="file-link"
                    rel="noreferrer"
                  >
                    {t("seeFile")}
                  </a>
                ) : null}
                <input
                  type="file"
                  disabled={isEdit}
                  name="requisites"
                  className="file-input"
                  onChange={(e) => setCompanyReq(e.target.files[0])}
                  required={!isEdit}
                />
              </Form.Item>
            </Col>

            <Col lg={24} xs={24}>
              <Form.Item
                label={t("mineral.comments")}
                name="comments"
                rules={[
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder={t("mineral.comments")}
                />
              </Form.Item>
            </Col>

            <Divider />

            <Col
              span={24}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              <Form.Item name="isReregister">
                <Checkbox
                  value={isReregister}
                  checked={isReregister}
                  onChange={(e) => setIsReregister(e.target.checked)}
                >
                  {t("mineral.reregister")}
                </Checkbox>
              </Form.Item>
            </Col>

            {isReregister ? (
              <>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label={`* ${t("mineral.oldCert")}`}
                    name="old_certificate"
                  >
                    <input
                      type="file"
                      name="old_certificate"
                      className="file-input"
                      onChange={(e) => setOldCertFile(e.target.files[0])}
                      required={!isEdit}
                    />
                  </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                  <Form.Item
                    label={t("mineral.oldCertnum")}
                    name="old_cert_number"
                    rules={[
                      {
                        required: true,
                        message: t("inner-fcc.error"),
                      },
                    ]}
                  >
                    <Input size="large" type="number" placeholder="0" />
                  </Form.Item>
                </Col>
              </>
            ) : null}

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.name")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.patentName")}
                name="generic_name"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.patentName")} />
              </Form.Item>
            </Col>

            <Col lg={1} xs={24}>
              <div
                className="icon-wrap"
                onClick={() => setOpenAddSubstanseModal(true)}
              >
                <IoMdAdd />
              </div>
            </Col>

            <Col lg={11} xs={24}>
              <Form.Item
                label={t("mineral.activeSubs")}
                name="substances"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={substances}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  mode="multiple"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.crop")}
                name="for_crop"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={makeOptions(
                    crops,
                    `${lang === "uz" ? "name_uz_l" : `name_${lang}`}`
                  )}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  mode="multiple"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.shape")}
                name="product_shape"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={makeOptions(
                    shapes,
                    `${lang === "uz" ? "name_uz_l" : `name_${lang}`}`
                  )}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.dose")}
                name="spend_rate"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.dose")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.concent")}
                name="concentration"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.concent")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.harm")}
                name="harmful_org"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.harm")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.method")}
                name="use_method"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.method")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.period")}
                name="use_period"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.period")} />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.repeat")}
                name="use_repeat"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={t("mineral.repeat")} />
              </Form.Item>
            </Col>

            <Col lg={8} xs={16}>
              <Form.Item
                label={t("mineral.validOpen")}
                name="valid_after_open"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" placeholder="0" type="number" />
              </Form.Item>
            </Col>

            <Col lg={4} xs={8}>
              <Form.Item
                label={t("inner-fcc.tanla")}
                name="valid_after_open_unit"
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={
                    lang === "uz"
                      ? valid_after_open_units_uz
                      : valid_after_open_units_ru
                  }
                  size="large"
                  defaultValue={"MONTH"}
                  value={valid_after_open_unit}
                  onChange={(e) => setvalid_after_open_unit(e)}
                />
              </Form.Item>
            </Col>

            <Col lg={8} xs={16}>
              <Form.Item
                label={t("mineral.valid")}
                name="valid_period"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Input size="large" placeholder="0" type="number" />
              </Form.Item>
            </Col>

            <Col lg={4} xs={8}>
              <Form.Item label={t("inner-fcc.tanla")} name="valid_period_unit">
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={
                    lang === "uz"
                      ? valid_after_open_units_uz
                      : valid_after_open_units_ru
                  }
                  defaultValue={"MONTH"}
                  size="large"
                  value={valid_period_unit}
                  onChange={(e) => setvalid_period_unit(e)}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.before_harvest_day")}
                name="before_harvest_day"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t("mineral.before_harvest_day")}
                />
              </Form.Item>
            </Col>

            <Col lg={24} xs={24}>
              <Form.Item
                label={t("mineral.keepInfo")}
                name="valid_saving_info"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder={t("mineral.keepInfo")}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.package_volume")}
                name="package_volume"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                  {
                    message:
                      langType === "UZ"
                        ? "Faqat lotin harf va belgilardan foydalaning"
                        : "Используйте только буквы и символы кириллица!",
                    validator: (_, value) => {
                      if (regex && value) {
                        if (regex.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="0" />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.package_volume_type")}
                name="package_volume_type"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={package_volume_type_options}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col lg={1} xs={24}>
              <div
                className="icon-wrap"
                onClick={() => setIsModalOpenManufacturers(true)}
              >
                <IoMdAdd />
              </div>
            </Col>

            <Col lg={11} xs={24}>
              <Form.Item
                label={t("mineral.manufacturer")}
                name="manufacturer"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={makeOptions(manufacturers, "name")}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                  onSearch={(e) => setSearchTerm(e)}
                />
              </Form.Item>
            </Col>

            <Col lg={12} xs={24}>
              <Form.Item
                label={t("mineral.productType")}
                name="product_type"
                rules={[
                  {
                    required: true,
                    message: t("inner-fcc.error"),
                  },
                ]}
              >
                <Select
                  placeholder={t("inner-fcc.tanla")}
                  options={makeOptions(
                    preparatTypes,
                    `${lang === "uz" ? "type_title" : `type_${lang}`}`
                  )}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                />
              </Form.Item>
            </Col>

            {isReregister ? null : (
              <Col lg={12} xs={24}>
                <Form.Item
                  label={t("mineral.agreeLetter")}
                  name="man_consent_letter"
                >
                  {agreeLetterView ? (
                    <a
                      href={agreeLetterView}
                      target="_blank"
                      style={{ color: "#036323", fontWeight: "500" }}
                      className="file-link"
                      rel="noreferrer"
                    >
                      {t("seeFile")}
                    </a>
                  ) : null}
                  <input
                    type="file"
                    name="man_consent_letter"
                    className="file-input"
                    onChange={(e) => setAgreeLetter(e.target.files[0])}
                  />
                </Form.Item>
              </Col>
            )}

            {isReregister ? null : (
              <Col lg={12} xs={24}>
                <Form.Item
                  label={`* ${t("mineral.certificate")}`}
                  name="man_certificate"
                >
                  {certificateView ? (
                    <a
                      href={certificateView}
                      target="_blank"
                      style={{ color: "#036323", fontWeight: "500" }}
                      className="file-link"
                      rel="noreferrer"
                    >
                      {t("seeFile")}
                    </a>
                  ) : null}
                  <input
                    type="file"
                    name="man_certificate"
                    className="file-input"
                    onChange={(e) => setCertificate(e.target.files[0])}
                    required={!isEdit}
                  />
                </Form.Item>
              </Col>
            )}

            {isReregister ? null : (
              <Col lg={12} xs={24}>
                <Form.Item
                  label={`${t("mineral.certificate_translated")}`}
                  name="man_certificate_translated"
                >
                  {certificateTranslatedView ? (
                    <a
                      href={certificateTranslatedView}
                      target="_blank"
                      style={{ color: "#036323", fontWeight: "500" }}
                      className="file-link"
                      rel="noreferrer"
                    >
                      {t("seeFile")}
                    </a>
                  ) : null}
                  <input
                    type="file"
                    name="man_certificate_translated"
                    className="file-input"
                    onChange={(e) =>
                      setCertificateTranslated(e.target.files[0])
                    }
                  />
                </Form.Item>
              </Col>
            )}

            {isReregister ? null : (
              <Col lg={12} xs={24}>
                <Form.Item label={t("mineral.license")} name="license">
                  {licenseView ? (
                    <a
                      href={licenseView}
                      target="_blank"
                      style={{ color: "#036323", fontWeight: "500" }}
                      className="file-link"
                      rel="noreferrer"
                    >
                      {t("seeFile")}
                    </a>
                  ) : null}
                  <input
                    type="file"
                    name="license"
                    className="file-input"
                    onChange={(e) => setLicense(e.target.files[0])}
                  />
                </Form.Item>
              </Col>
            )}

            <Col lg={12} xs={24}>
              <Form.Item
                label={`* ${t("mineral.infoDrugChemical")}`}
                name="product_file"
              >
                {productFileView ? (
                  <a
                    href={productFileView}
                    target="_blank"
                    style={{ color: "#036323", fontWeight: "500" }}
                    className="file-link"
                    rel="noreferrer"
                  >
                    {t("seeFile")}
                  </a>
                ) : null}
                <input
                  type="file"
                  name="product_file"
                  className="file-input"
                  onChange={(e) => setProductFile(e.target.files[0])}
                  required={!isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={4} span={7}>
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                title={
                  "Barcha kiritilgan ma'lumotlar tozalanib ketadi! Davom etasizmi?"
                }
                onConfirm={cancel}
                okText="Ha"
                cancelText="Yo'q"
              >
                <Button
                  loading={isLoading}
                  style={{ width: "100%", boxShadow: "none", padding: "0px" }}
                  size="large"
                >
                  {t("inner-fcc.cancel")}
                </Button>
              </Popconfirm>
            </Col>
            <Col span={2}></Col>
            {appData?.is_active === false ? null : (
              <Col span={7}>
                <Button
                  loading={isLoading}
                  style={{ width: "100%", boxShadow: "none", padding: "0px" }}
                  size="large"
                  htmlType="submit"
                  type="primary"
                >
                  {t("inner-fcc.send")}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChemicalModal;
