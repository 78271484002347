import { useQuery } from "@tanstack/react-query";
import { Button, Col, Tooltip } from "antd";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getManufacturers } from "../../../../../../apis/guvohnoma";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { PlusOutlined } from "@ant-design/icons";
import ManufacturersModal from "./manufacturersModal";
import AddManModal from "./AddManModal";

export const Manufacturer = () => {
  const { t } = useTranslation();
  const [addManModal, setAddManModal] = useState(false);
  const [search, setSearch] = useState("");
  const [isModalOpenManufacturers, setIsModalOpenManufacturers] =
    useState(false);
  const { control } = useFormContext();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      getManufacturers({
        page: 1,
        page_size: 40,
        search: search ?? null,
      }),
    queryKey: [REACT_QUERY_KEYS.MINERAL_MANUFACT, search],
    enabled: search === "" || search?.length > 3,
    select: (res) => {
      return res.data.data?.results?.map((pr) => ({
        value: pr.id,
        label: pr.name,
      }));
    },
  });
  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  return (
    <Col span={24} md={12}>
      <div style={{ width: "100%", display: "flex", gap: "8px" }}>
        <div style={{ width: "calc(100% - 40px)" }}>
          <FormSelect
            {...props}
            label={t("mineral.manufacturer")}
            placeholder={t("inner-fcc.tanla")}
            name="product.manufacturer"
            onSearch={(e) => setSearch(e)}
            options={data}
            loading={isLoading}
          />
        </div>
        <Tooltip title="Iltimos yangi yaratishdan oldin qidirb ko'ring, topilmasa keyin yarating!">
          <Button
            onClick={() => setIsModalOpenManufacturers(true)}
            style={{ marginTop: "30px" }}
            icon={<PlusOutlined />}
          />
        </Tooltip>
      </div>

      <ManufacturersModal
        isModalOpenManufacturers={isModalOpenManufacturers}
        setIsModalOpenManufacturers={setIsModalOpenManufacturers}
        setAddManModal={setAddManModal}
        getManufacturers={getManufacturers}
        setSearch={setSearch}
      />
      <AddManModal open={addManModal} setOpen={setAddManModal} />
    </Col>
  );
};
