import { Button, Input, Modal, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { requestReg } from '../../../helpers/createRequest';
import CustomTable from '../../../components/custom-table';
import { makeOptions } from '../../../utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ManufacturersModal = (props) => {
    const { isModalOpenManufacturers, setIsModalOpenManufacturers, setAddManModal } = props

    const lang = useSelector((state) => state.language.lang);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false)
    const [allManufacturers, setAllManufacturers] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')
    const [countries, setCountries] = useState([])
    const [countryId, setCountryId] = useState(null)

    const columns = useMemo(
        () => [
            {
                title: `#`,
                key: 'numberOfRow',
                fixed: 'left',
                align: 'center',
                width: 50,
                render: (text, obj, index) => {
                    return (
                        <span> {(pageNumber - 1) * pageSize + index + 1} </span>
                    )
                },
                checked: true,
            },
            {
                title: `${t('common.name')}`,
                dataIndex: 'name',
                key: 'name',
                type: 'string',
                align: 'center',
                width: 200,
                checked: true,
            },
            {
                title: `${t('common.country')}`,
                dataIndex: 'country',
                key: 'country',
                type: 'string',
                align: 'center',
                width: 200,
                checked: true,
                render: (row) => {
                    return (
                        <span>{row ? lang === 'uz' ? row[`name_uz_l`] : row[`name_${lang}`] : null}</span>
                    )
                }
            },
            {
                title: `${t('addMan.email')} / ${t('addMan.website')}`,
                key: 'email',
                type: 'string',
                align: 'center',
                width: 200,
                checked: true,
                render: (row) => {
                    return (
                        <span>{row?.email} <br /> {row?.website}</span>
                    )
                }
            },
            {
                title: `${t('mineral.manufacturer')} / ${t('addMan.phone')}`,
                key: 'owner_fullname',
                type: 'string',
                align: 'center',
                width: 250,
                checked: true,
                render: (row) => {
                    return (
                        <span>{row?.owner_fullname} <br /> {row?.phone}</span>
                    )
                }
            },

            // eslint-disable-next-line
        ], [pageNumber, pageSize, lang]
    )

    const getCountries = async () => {
        try {
            const res = await requestReg.get(`/item/countries`)
            setCountries(res?.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    const getManufacturers = async () => {
        setIsLoading(true)
        await requestReg
            .get(`product/manufacturers`, {
                params: {
                    country_id: countryId,
                    search: searchTerm?.length >= 4 ? searchTerm : null,
                    page: pageNumber
                }
            })
            .then((res) => {
                if (res?.data?.success) {
                    setAllManufacturers(res?.data?.data?.results);
                    setTotalCount(res?.data?.data?.count);
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false)
            })
    };

    useEffect(() => {
        getManufacturers()

        // eslint-disable-next-line
    }, [countryId, searchTerm, pageNumber])

    useEffect(() => {
        getCountries()
    }, [])

    return (
        <Modal
            width={980}
            open={isModalOpenManufacturers}
            onCancel={() => setIsModalOpenManufacturers(false)}
            footer={[]}
        >
            <h1 style={{ fontSize: '24px' }}>{t("mineral.manufacturer")}</h1>

            <div className='filter-row'>
                <div style={{ marginLeft: 'auto' }}></div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>{t('common.country-filter')}:</label>
                    <Select
                        placeholder={t('inner-fcc.tanla')}
                        options={makeOptions(countries, lang === 'uz' ? 'name_uz_l' : `name_${lang}`)}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label || '').toLowerCase().includes(input.toLowerCase())
                        }
                        value={countryId}
                        onChange={(e) => setCountryId(e)}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>{t('balance.show')}:</label>
                    <Input.Search
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        placeholder={t('balance.show')}
                        allowClear
                        width={'300px'}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>{t("addMan.createMan")}</label>
                    <Button
                        type='primary'
                        style={{ boxShadow: 'none' }}
                        onClick={() => setAddManModal(true)}
                    >
                        + {t('create')}
                    </Button>
                </div>
            </div>

            <CustomTable
                name="manufacturers"
                columns={columns}
                data={allManufacturers}
                size="small"
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                isLoading={isLoading}
                pageSize={pageSize}
                pageNumber={pageNumber}
                totalCount={totalCount}
            />
        </Modal>
    )
}

export default ManufacturersModal