import { LINK } from "../types/Types"

const initialState = {
    link: window.location.pathname
    // link: '/inner-fcc'
}

const linkReducer = (state = initialState, action) => {
    switch (action.type) {
        case LINK:
            return {
                ...state,
                link: action.payload
            }
        default:
            return state
    }
}

export default linkReducer