import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { requestReg } from "../../helpers/createRequest";
import { toast } from "react-toastify";
import { antIcon } from "../invoice/Invoice";
import { reFormat } from "../../utils";

const ConfirmModal = ({ open, setOpen, appId, ...props }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [check, setCheck] = useState(null);
  const [price, setPrice] = useState(0);

  const getInvoiceInfo = async () => {
    try {
      const response = await requestReg.get(`product/application/invoice/${appId}`)
      setPrice(response?.data?.data?.price)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    if (check) {
      formData.append("file", check);
    }
    await requestReg
      .post(`product/application/invoice/${appId}`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("To'lov tasdiqlandi!");
        }
        setIsLoading(false);
        setOpen();
      })
      .catch((e) => {
        if (e.response.status === 402) {
          toast.error(
            `Hisobingizda ${e.response.data.amount} so'm bo'lishi kerak!`
          );
        } else {
          toast.error("Xatolik!");
        }
        setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getInvoiceInfo()

    // eslint-disable-next-line
  }, [appId])

  return (
    <Modal
      open={open}
      onOk={setOpen}
      onCancel={setOpen}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={500}
      className="add-fcc-modal"
    >
      <h2 style={{ lineHeight: '32px' }}>Guvohnoma uchun to'lov summasi <br /> <b>{reFormat(price)} so'm</b></h2>
      <form>
        <div className="roow">
          <div className="row-full" style={{ width: '100%' }}>
            <label>{t("mineral.infoDrug")}</label>
            <input type="file" onChange={(e) => setCheck(e.target.files[0])} />
          </div>
        </div>
        <div className="btn-wrap compare-btn">
          <button
            type="submit"
            className={!isLoading ? "button-active" : ""}
            disabled={isLoading}
            onClick={(e) => fetchData(e)}
          >
            {isLoading ? <Spin indicator={antIcon} /> : t("confirm")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmModal;
