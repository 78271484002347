import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, userLogout } from "../../redux/actions/loginAction";
// import { navigate } from "../../utils/navigate";
// import queryString from "query-string";
// import Loader from "../loader/Loader";
// import { API_URL, REG_URL } from "../../constants/variables";
import { requestReg, requestRegAuth } from "../../helpers/createRequest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./login.css";
import logoImg from "../../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";
import Loader from "../loader/Loader";
import i18next from "i18next";
import { language } from "../../redux/actions/langAction";
import { links } from "../../redux/actions/linksAction";

const Login = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const baseURL = REG_URL;
  const { token } = useSelector((state) => state.login);
  // const newToken = JSON.parse(localStorage.getItem("user_data"))?.token;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { lang: lan } = useSelector((state) => state.language);

  const { link } = useSelector((state) => state.link);
  const changeLink = (name) => {
    dispatch(links(name));
  };

  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };

  // const queryParams = queryString.parse(window.location.search);

  const onSubmitHandle = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const data = {
      email: email,
      password: password,
    };

    requestRegAuth
      .post("user/foreign/login", data)
      .then((res) => {
        if (res.data.success) {
          dispatch(userLogin(res.data.data));
          window.location.reload();
          // setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.data?.error) {
          toast.error(e.response.data.error);
        }
        toast.error(t("application.error"));
        setIsLoading(false);
      });
  };
  // let code = window.location.search.split("&")[0].split("=")[1];
  // let code = queryParams.code;
  // let stateTxt = queryParams.state;
  // const login = () => {
  //   axios
  //     .post(`${baseURL}/applicant/auth/one-id/`, { code: code })
  //     .then((res) => {
  //       dispatch(userLogin(res.data));
  //       if (stateTxt == "fumPrice") {
  //         window.location.href = "fumigation?price=1";
  //       }
  //       // window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.status === 401) {
  //         navigate();
  //         dispatch(userLogout());
  //       }
  //       if (err.response.status === 403) {
  //         alert("Xatolik! Birozdan so'ng urinib ko'ring");
  //         window.location.replace("https://karantin.uz");
  //         dispatch(userLogout);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (!code) {
  //     navigate();
  //   } else {
  //     // localStorage.removeItem("pop_status");
  //     login();
  //   }
  // }, []);

  return (
    <>
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <div className="select-login login">
        <div className="wrapper-card">
          <div className="card-content-wrap">
            <div className="langs-outer">
              <div className="langs" style={{ maxWidth: "80px" }}>
                {/* <div
                  className={lan === "uz" ? "active-lan" : ""}
                  onClick={() => changeLan("uz")}
                >
                  UZ
                </div> */}
                <div
                  className={lan === "ru" ? "active-lan" : ""}
                  onClick={() => changeLan("ru")}
                >
                  RU
                </div>
                <div
                  className={lan === "uz" ? "active-lan" : ""}
                  onClick={() => changeLan("uz")}
                >
                  UZ
                </div>
              </div>
            </div>
            <div className="logo-content">
              <img src={logoImg} />
              <p style={{ fontWeight: "500" }}>{t("navbar.logo")}</p>
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "500",
              }}
            >
              {t("login.login")}
            </h2>

            <form onSubmit={onSubmitHandle}>
              <div className="roow">
                <div>
                  <label>{t("login.email")}</label>
                  <input
                    type="text"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label>{t("login.password")}</label>
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div>
                  <NavLink
                    to="/signup"
                    className="sign-link"
                    onClick={() => changeLink("/signup")}
                  >
                    {t("login.signup")}
                  </NavLink>
                </div>
              </div>

              <div className="btn-wrapper">
                <button
                  type="submit"
                  className={
                    !isLoading ? "login-btn button-active" : "login-btn"
                  }
                  disabled={isLoading}
                >
                  {t("login.signin")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Login;
