import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import logo from "../../assets/images/logo.svg";
import "./navbar.css";
import { language } from "../../redux/actions/langAction";
import { BiMenuAltLeft } from "react-icons/bi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { links } from "../../redux/actions/linksAction";
import { ReactComponent as Ring } from "../../assets/icons/notification.svg";
import { ReactComponent as RingRed } from "../../assets/icons/notificationred.svg";

const Navbar = ({ menu, setMenu }) => {
  const { t } = useTranslation();
  const { lang: lan } = useSelector((state) => state.language);
  const { pinOrTin, name: nameUser } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(language(lang));
  };

  return (
    <div className="navbar-component">
      <Link to="/" onClick={() => dispatch(links("/invoice"))}>
        <div className="left">
          <img src={logo} alt="" className="logo" />
          <h1>{t("navbar.logo")}</h1>
        </div>
      </Link>
      <div className="navbar-center">
        {/* <AiOutlineExclamationCircle className="icon" />
        <p>{t("navbar.test")}</p> */}
      </div>
      <div className="menu-icon-wrap" onClick={() => setMenu(!menu)}>
        <BiMenuAltLeft className="menu-icon" />
      </div>
      <div className="right">
        {/* <div className="notification">
          <div>
            <Link
              to="/notifications"
              onClick={() => dispatch(links("/notifications"))}
            >
              <RingRed />
            </Link>
          </div>
        </div> */}
        <div className="langs">
          <div
            className={lan === "uz" ? "active-lan" : ""}
            onClick={() => changeLan("uz")}
          >
            UZ
          </div>
          {/* <div className={lan === 'en' ? 'active-lan' : ''} onClick={() => changeLan('en')}>EN</div> */}
          <div
            className={lan === "ru" ? "active-lan" : ""}
            onClick={() => changeLan("ru")}
          >
            RU
          </div>
        </div>
        <div className="user">
          <div className="bg"></div>
          <div className="text">
            <div>
              <h2>
                {/* {nameUser.split(" ")[0]} {nameUser.split(" ")[1].slice(0, 1)}. */}
                {nameUser}
              </h2>
            </div>

            <h3>{pinOrTin}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
