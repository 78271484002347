import axios from "axios";
import { toast } from "react-toastify";
import {
  AGRO_URL,
  AGRO_URL_NEW,
  BASE_URL,
  REG_TOKEN,
  REG_URL,
} from "../constants/variables";
import { navigate } from "../utils/navigate";

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// export const setAuthToken = (token) => {
//   if (token) {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   }
// };

export const request = axios.create({
  baseURL: BASE_URL,
  // headers: {
  //   Authorization:
  //     "Bearer " + JSON.parse(localStorage.getItem("user_data"))?.token,
  //   "X-CSRFToken": getCookie("csrftoken"),
  //   "Content-Type": "application/json",
  // },
});

export const requestPublic = axios.create({
  baseURL: BASE_URL,
});

export const requestReg = axios.create({
  baseURL: REG_URL,
  // headers: {
  //   Authorization:
  //     "Bearer " + JSON.parse(localStorage.getItem("user_data"))?.access,
  //   "X-CSRFToken": getCookie("csrftoken"),
  //   "Content-Type":
  //     "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  // },
});

export const requestRegAuth = axios.create({
  baseURL: REG_URL,
  headers: {
    // Authorization: "Bearer " + REG_TOKEN,
    "X-CSRFToken": getCookie("csrftoken"),
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  },
});

export const fetchRequest = axios.create({
  baseURL: AGRO_URL_NEW,
  // headers: {
  //   Authorization:
  //     "Bearer " + JSON.parse(localStorage.getItem("user_data"))?.token,
  //   "X-CSRFToken": getCookie("csrftoken"),
  //   "Content-Type": "application/json",
  // },
});

export const fetchRequestMineral = axios.create({
  baseURL: AGRO_URL,
  // headers: {
  //   Authorization:
  //     "Bearer " + JSON.parse(localStorage.getItem("user_data"))?.token,
  //   "X-CSRFToken": getCookie("csrftoken"),
  //   "Content-Type": "application/json",
  // },
});

export const requestWithFile = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: localStorage.getItem("token"),
    "X-CSRFToken": getCookie("csrftoken"),
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
  },
});

export const authRequest = axios.create({
  baseURL: BASE_URL,
  "X-CSRFToken": getCookie("csrftoken"),
  headers: {
    "Content-Type": "application/json",
  },
});

export const subscribe = (store) => {
  requestReg.interceptors.request.use(
    (config) => {
      // const csrftoken = getCookie("csrftoken");
      // console.log(JSON.parse(localStorage.getItem("user_data"))?.token)
      const token = JSON.parse(localStorage.getItem("user_data"))?.access;
      config.headers.Authorization = "Bearer " + token;

      // [
      //   "Bearer",
      //   JSON.parse(localStorage.getItem("user_data")).token,
      // ].join(" ");

      // if (csrftoken) {
      //   config.headers.common["X-CSRFToken"] = csrftoken;
      // }

      return config;
    },
    (err) => {
      toast.error(err.response.data.message);
    }
  );

  requestReg.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status == 401) {
        // localStorage.removeItem('token');
        // window.location.href = '/login';
        window.localStorage.removeItem("user_data");
        // navigate();
        return Promise.reject(error);
      } else if (error.response.status == 400) {
        // alert(JSON.stringify(error.response.data));
        // return error.response;
        return Promise.reject(error);
      } else if (error.response.status == 500) {
        alert("Тизим вақтинчалик ўзгаришлар жараёнида");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default request;
