import { Space, Switch } from "antd";
import React from "react";
import { useController } from "react-hook-form";

export const FormToggle = ({
  name,
  control,
  styleWrapper,
  label,
  myref,
  ...props
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  return (
    <Space size="middle" style={{ width: "100%", ...styleWrapper }}>
      {label && <label>{label}</label>}
      <div ref={myref}>
        <Switch {...props} onChange={onChange} checked={value} />
      </div>
    </Space>
  );
};
