const dayjs = require('dayjs');

export const makeOptions = (data, label, value = 'id') => {
    if (!data) {
        return [];
    }
    return data?.map((item) => ({
        label: typeof label === 'function' ? label(item) : item[label],
        value: item[value],
    })) || [];
};


export const setLocalTime = (date, template) => {
    return dayjs(new Date(date)).format(template || 'YYYY-MM-DD H:mm')
}

export function reFormat(num) {
    return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}