import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalDetail } from "../modal";

export const ModalButton = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const config = {
    title: t("close modal"),
    content: t("close modal desc"),
    icon: <ExclamationCircleOutlined />,
    okText: t("close"),
    cancelText: t("lab.back"),
    centered: true,
    onOk: () => {
      setIsModalOpen(false);
    },
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    modal.confirm(config);
  };

  return (
    <>
      {data ? (
        <Tooltip placement="topLeft" title={t("edit")}>
          <Button type="primary" onClick={showModal} icon={<EditOutlined />} />
        </Tooltip>
      ) : (
        <Button
          style={{ boxShadow: "none" }}
          size="large"
          type="primary"
          onClick={showModal}
        >
          {t("inner-fcc.single")}
        </Button>
      )}
      <Modal
        open={isModalOpen}
        centered
        footer={null}
        className="my-modal"
        width={1200}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <ModalDetail
          data={data}
          open={isModalOpen}
          onCancel={handleCancel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      {contextHolder}
    </>
  );
};
