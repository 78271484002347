import { GET_LISTS_ERROR, GET_LISTS_SUCCESS } from "../types/Types"

const initialState = {
    loading: true,
    lists: [],
    error: null
}

const listsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LISTS_SUCCESS:
            return {
                ...state,
                lists: action.payload,
                loading: false,
                error: null
            }
        case GET_LISTS_ERROR:
            return {
                ...state,
                lists: [],
                loading: false,
                error: true
            }
        default:
            return state
    }
}

export default listsReducer