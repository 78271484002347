import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { requestReg } from "../../../helpers/createRequest";
import { toast } from "react-toastify";
import { antIcon } from "../../invoice/Invoice";

const AddSubstanceModal = ({ open, setOpen, getSubstances }) => {
  const { t } = useTranslation();

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [substance, setSubstance] = useState("");
  const [manufacturer, setManufacturer] = useState("");

  const checkBtnActive = () => {
    if (substance.length > 0 && manufacturer.length > 0) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  };

  useEffect(() => {
    checkBtnActive();

    // eslint-disable-next-line
  }, [substance, manufacturer]);

  const fetchData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await requestReg
      .post(`product/substances`, {
        substance: substance,
        company: manufacturer,
      })
      .then((res) => {
        setIsLoading(false);
        toast.success("Ta'sir etuvchi modda muvaffaqiyatli qo'shildi!");
        setSubstance("");
        setManufacturer("");
        setOpen(false);
        getSubstances();
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        toast.error(e.message);
      });
  };

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={875}
      className="add-my-fcc-modal"
    >
      <h1>{t("createSub")}</h1>
      <form>
        <div className="roow">
          <div>
            <label>{t("mineral.activeSub")}</label>
            <input
              type="text"
              value={substance}
              required
              onChange={(e) => setSubstance(e.target.value)}
            />
          </div>
          <div>
            <label>{t("mineral.manufacturer")}</label>
            <input
              type="text"
              value={manufacturer}
              onChange={(e) => setManufacturer(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="btn-wrap compare-btn">
          <button
            type="submit"
            className={isBtnActive ? "button-active" : ""}
            disabled={!isBtnActive}
            onClick={(e) => fetchData(e)}
          >
            {isLoading ? <Spin indicator={antIcon} /> : t("inner-fcc.send")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddSubstanceModal;
