import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from 'redux-thunk'
import langReducer from "./reducers/langReducer";
import linkReducer from "./reducers/linkReducer";
import listsReducer from "./reducers/listsReducer";
import loginReducer from "./reducers/loginReducer";

const rootReducers = combineReducers({
    language: langReducer,
    link: linkReducer,
    login: loginReducer,
    lists: listsReducer
})

const store = createStore(rootReducers, applyMiddleware(thunkMiddleware))
export default store