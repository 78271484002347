import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { getTypes } from "../../../../../../apis/guvohnoma";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";

export const useMyform = () => {
  const { control, watch } = useFormContext();

  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const lang_props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
      pattern:
        watch("application.language") === "UZ"
          ? {
              value: /^[^\u0400-\u04FF]+$/,
              message:
                "Iltimos faqat lotin  alifbolari yordamida ma'lumot kiriting",
            }
          : {
              value: /^[ЁёА-я0-9!@#$&()%^&+_[|/?=.,"°'{}`* -\]]+$/,
              message:
                "Пожалуйста, вводите информацию, используя только кириллицу",
            },
    },
  };

  const { data: preparationTypes, isLoading: preparationTypesLoading } =
    useQuery({
      queryFn: () =>
        getTypes({
          type: "chemical",
        }),
      queryKey: [REACT_QUERY_KEYS.PREPARATION_TYPES],
      select: (res) => {
        return res.data.data?.map((pr) => ({
          value: pr.id,
          label:
            watch("application.language") === "UZ" ? pr.type_uz_l : pr.type_ru,
        }));
      },
    });
  return {
    props,
    lang_props,
    preparationTypes,
    preparationTypesLoading,
    isRegistered: watch("application.is_reregister"),
    isEdit: !!watch("company.id"),
  };
};
