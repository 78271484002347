import React from 'react'
import './loader.css'

const Loader = () => {
    return (
        <div className='loader-component'>
            <div className="loadingio-spinner-double-ring-v4wsvp38mc">
                <div className="ldio-grm73x5ykr7">
                    <div></div>
                    <div></div>
                    <div>
                        <div>
                    </div>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Loader