import { DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Modal, Row, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { applicationDelete } from "../../../../apis/guvohnoma";
import { FormTextArea } from "../../../../components/formComponents/textarea/textarea";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";
const { Title } = Typography;
export const DeleteAppButton = ({ rowId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const form = useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { mutate, isLoading } = useMutation(
    async (data) => {
      return await applicationDelete(data, rowId);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_KEYS.MINERAL_APP_LIST],
        });
        toast.success(t("delete app toast"));
        handleCancel();
      },
      onError: () => {
        toast.error("Error");
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data.user_cancel_reason);
  };
  return (
    <>
      <Tooltip placement="topRight" title={t("delete app")}>
        <Button
          onClick={showModal}
          type="text"
          danger
          ghost
          icon={<DeleteOutlined />}
        />
      </Tooltip>

      <Modal
        open={isModalOpen}
        centered
        footer={null}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title style={{ textAlign: "center" }} level={3}>
                {t("delete app")}
              </Title>
            </Col>
            <Col span={24}>
              <FormTextArea
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: "inner-fcc.error",
                  },
                }}
                label={t("mineral.comments")}
                name="user_cancel_reason"
              />
            </Col>
            <Col span={24}>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  onClick={handleCancel}
                >
                  {t("lab.back")}
                </Button>
                <Button
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("lab.delete")}
                </Button>
              </Space>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};
