import React, { useState } from "react";

const ItemContext = React.createContext({
  //   districts: [],
  acts: {},
});

export const ItemContextProvider = (props) => {
  //   const [districts, setDistricts] = useState([]);
  const [acts, setActs] = useState({});

  const fetchActsHandler = (acts) => {
    setActs(acts);
  };

  //   const fetchDistrictHandler = (districts) => {
  //     setDistricts(districts);
  //   };

  const contextValue = {
    // districts: districts,
    acts: acts,
    fetchActs: fetchActsHandler,
    // fetchDistrict: fetchDistrictHandler,
  };

  return (
    <ItemContext.Provider value={contextValue}>
      {props.children}
    </ItemContext.Provider>
  );
};

export default ItemContext;
