import { useQuery } from "@tanstack/react-query";
import { Col } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getSubstances } from "../../../../../../apis/guvohnoma";
import { FormSelect } from "../../../../../../components/formComponents/select";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";
import { DetailModal } from "./detail";

export const SubstancesComponent = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { data, isLoading } = useQuery({
    queryFn: getSubstances,
    queryKey: [REACT_QUERY_KEYS.MINERAL_SUBSTANCES],
    select: (res) => {
      return res.data.data?.map((pr) => ({
        value: pr.id,
        label: `${pr.substance} / ${pr.company}`,
      }));
    },
  });
  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };

  return (
    <Col span={24} md={12}>
      <div style={{ width: "100%", display: "flex", gap: "8px" }}>
        <div style={{ width: "calc(100% - 40px)" }}>
          <FormSelect
            {...props}
            multiple={true}
            label={t("mineral.activeSubs")}
            placeholder={t("inner-fcc.tanla")}
            name="product.substances"
            options={data}
            loading={isLoading}
          />
        </div>
        <DetailModal />
      </div>
    </Col>
  );
};
