import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Dropdown, Table } from 'antd'
import { BsFilter } from "react-icons/bs"
import { useLocalStorageState } from 'ahooks'
import './custom-table.css'

const CustomTable = (props) => {
    const { name, data, columns, size, isLoading, setPageNumber, setPageSize, pageNumber, totalCount, pageSize } = props

    const [filterColumns, setFilterColumns] = useLocalStorageState(`table-columns-${name}`, {
        defaultValue: {},
    });
    const [isOpenFilterDropdown, setIsOpenFilterDropdown] = useState(false)

    const mergedColumns = useMemo(() => {
        return columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    title: col?.title,
                }),
            };
        })
    }, [columns])

    useEffect(() => {
        if (filterColumns && Object.keys(filterColumns).length > 1) return
        let obj = {};
        mergedColumns.forEach((item) => {
            obj[item.key] = { key: item.key, checked: !item.hide };
        })

        setFilterColumns(obj)
        // eslint-disable-next-line
    }, [mergedColumns, filterColumns])

    const filteredColumns = useMemo(
        () =>
            mergedColumns.filter((item) => filterColumns && filterColumns[item.key]?.checked),
        [mergedColumns, filterColumns],
    )

    const items = mergedColumns.map((item) => ({
        key: item.key,
        label: (
            <>
                <Checkbox
                    checked={filterColumns && !!filterColumns[item.key]?.checked}
                    onChange={(e) => { }}
                    style={{ marginRight: '10px' }}
                ></Checkbox>
                {item.title}
            </>
        ),
    }))

    const onFilterColumn = (checked, key) => {
        setFilterColumns({ ...filterColumns, [key]: { key: key, checked } });
    }

    return (
        <>
            <Dropdown
                menu={{
                    items,
                    onClick: (e) => {
                        onFilterColumn(!!filterColumns && !filterColumns[e.key]?.checked, e.key)
                    },
                }}
                placement="bottomRight"
                arrow
                trigger={"click"}
                open={isOpenFilterDropdown}
                onOpenChange={(flag) => {
                    setIsOpenFilterDropdown(flag)
                }}
            >
                <div className='iconn mb-3 ml-auto' style={{ marginLeft: 'auto' }}>
                    <BsFilter />
                </div>
            </Dropdown>
            <Table
                name={name}
                dataSource={data}
                columns={filteredColumns}
                size={size}
                loading={isLoading}
                rowKey={"id"}
                pagination={{ defaultPageSize: pageSize, total: totalCount, current: pageNumber, showSizeChanger: true, pageSizeOptions: ['10'] }}
                onChange={(e) => {
                    setPageNumber(e.current)
                    setPageSize(e.pageSize)
                }}
                scroll={{
                    x: 'scroll',
                }}
            />
        </>
    )
}

export default CustomTable