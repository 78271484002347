import { Button, Col, Row, Space, Tour, Typography } from "antd";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSegmented } from "../../../../components/formComponents/segmented";
import { Chemical } from "../chemical";
import { Mineral } from "../mineral";
import { useModal } from "../useModal";
const { Title, Text } = Typography;
export const ModalDetail = ({
  isModalOpen,
  setIsModalOpen,
  data,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    onSubmit,
    isLoading,
    open,
    types,
    changeLang,
    app_lang,
    steps,
    ref1,
    ref2,
    ref3,
    setOpen,
    form,
  } = useModal({ setIsModalOpen, isModalOpen, data });
  const { control, watch, handleSubmit } = form;
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Title level={3}>
                {
                  types?.find((t) => t.value === watch("application.app_type"))
                    ?.label
                }
              </Title>
              {!data && (
                <Space
                  size="large"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Space
                    size="small"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Text style={{ fontSize: "17px", fontWeight: 500 }}>
                      {t("navbar.type-ariza")}:
                    </Text>
                    <div ref={ref1}>
                      <FormSegmented
                        name="application.app_type"
                        control={control}
                        options={types}
                        size="large"
                      />
                    </div>
                  </Space>
                  <Space
                    size="small"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Text style={{ fontSize: "17px", fontWeight: 500 }}>
                      {t("app-lang")}:
                    </Text>
                    <div ref={ref2}>
                      <FormSegmented
                        name="application.language"
                        control={control}
                        options={app_lang}
                        onChange={changeLang}
                        size="large"
                      />
                    </div>
                  </Space>
                </Space>
              )}
              <Tour
                mask={false}
                type="primary"
                open={open}
                onClose={() => {
                  setOpen(false);
                  localStorage.setItem("tour", "ended");
                }}
                steps={steps}
              />
            </Col>

            <Col span={24} className="main-content">
              {data?.file && (
                <div className="modal-inner warning">
                  <div className="warning-wrap common">
                    <p>{data?.cancel_reason}</p>
                    {data?.cancel_file && (
                      <a
                        href={data?.cancel_file}
                        target="_blank"
                        style={{ color: "#036323", fontWeight: "500" }}
                        className="file-link"
                        rel="noreferrer"
                      >
                        {t("seeFile")}
                      </a>
                    )}
                  </div>
                </div>
              )}

              {watch("application.app_type") === "CHEM" ? (
                <Chemical myRef={ref3} />
              ) : (
                <Mineral myRef={ref3} />
              )}
            </Col>

            <Col span={24}>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="large"
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  onClick={props.onCancel}
                >
                  {t("inner-fcc.cancel")}
                </Button>
                <Button
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("inner-fcc.send")}
                </Button>
              </Space>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </>
  );
};
