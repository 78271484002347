import { USER_LOGIN, USER_LOGOUT } from "../types/Types";

let initialToken = JSON.parse(window.localStorage.getItem("user_data"))?.access;
// let expiresIn = JSON.parse(
//   window.localStorage.getItem("user_data")
// )?.expires_in;
let name = JSON.parse(window.localStorage.getItem("user_data"))?.full_name;
// let pinOrTin = JSON.parse(window.localStorage.getItem("user_data"))?.pin_or_tin;
// let email = JSON.parse(window.localStorage.getItem("user_data"))?.email;
// let phone = JSON.parse(window.localStorage.getItem("user_data"))?.mob_phone_no;

const initialState = {
  token: initialToken,
  name,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        name: action.payload.name,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default loginReducer;
