import { useQuery } from "@tanstack/react-query";
import { Empty, Select } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getList } from "../../apis/guvohnoma";
import Loader from "../../components/loader/Loader";
import { REACT_QUERY_KEYS } from "../../constants/reactQueryKeys";
import ConfirmModal from "../chemicals/confirmModal";
import { ModalComponent } from "./components/alert-modal";
import { ModalButton } from "./components/layout";
import "./guvohnoma.css";
import ChemicalModal from "./modals/chemicalModal";
import MineralModal from "./modals/mineralModal";
import Warning from "./modals/warning";
import TableRow from "./tableRow";

const Guvohnoma = () => {
  const { t } = useTranslation();
  const { pinOrTin } = useSelector((state) => state.login);

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [isOpenChemical, setIsOpenChemical] = useState(false);
  const [isOpenMineral, setIsOpenMineral] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [appId, setAppId] = useState(null);

  const handleConfirmOpen = () => {
    setAppId(null);
    setConfirmModal(false);
  };

  const [allLists, setAllLists] = useState([]);
  const [balance, setBalance] = useState(null);
  const [appData, setAppData] = useState({});
  const [productType, setProductType] = useState(null);

  const openChemical = () => {
    if (pinOrTin.length === 14) {
      toast.warning(
        "Xizmatdan foydalanish uchun yuridik shaxs sifatida kiring!"
      );
    } else {
      setIsOpenChemical(true);
      setOpenPopover(false);
    }
  };

  const openMineral = () => {
    if (pinOrTin.length === 14) {
      toast.warning(
        "Xizmatdan foydalanish uchun yuridik shaxs sifatida kiring!"
      );
    } else {
      setIsOpenMineral(true);
      setOpenPopover(false);
    }
  };

  const { isLoading } = useQuery({
    queryFn: () =>
      getList({
        product_type: productType,
      }),
    queryKey: [REACT_QUERY_KEYS.MINERAL_APP_LIST, productType],
    select: (res) => res.data,
    onSuccess: (res) => {
      setAllLists(res?.data);
      setBalance(res?.balance);
    },
  });

  return (
    <div className="inner-fcc-page">
      {isOpenChemical ? (
        <ChemicalModal
          isOpenChemical={isOpenChemical}
          setIsOpenChemical={setIsOpenChemical}
          appData={appData}
          setAppData={setAppData}
          getList={getList}
        />
      ) : null}

      {isOpenMineral ? (
        <MineralModal
          isOpenMineral={isOpenMineral}
          setIsOpenMineral={setIsOpenMineral}
          appData={appData}
          setAppData={setAppData}
          getList={getList}
        />
      ) : null}

      <Warning
        setOpenWarningModal={setOpenWarningModal}
        openWarningModal={openWarningModal}
      />

      <div className="top">
        <h1>{t("navbar.mineralsChemicals")}</h1>
        <div className="right">
          <ModalButton />
          <ModalComponent />
          {/* <Popover
                        placement="bottomRight"
                        content={
                            <>
                                <Button
                                    className={"button-active"}
                                    type="primary"
                                    style={{ boxShadow: 'none' }}
                                    onClick={openChemical}
                                >
                                    {t("navbar.chemicals")}
                                </Button>
                                <Button
                                    className={"button-active"}
                                    type="primary"
                                    style={{ boxShadow: 'none' }}
                                    onClick={openMineral}
                                >
                                    {t("navbar.minerals")}
                                </Button>
                            </>
                        }
                        title={t("navbar.type-ariza")}
                        trigger="click"
                        open={openPopover}
                        onOpenChange={() => setOpenPopover(!openPopover)}
                    >
                        <Button
                            className={"button-active"}
                            type="primary"
                            style={{ boxShadow: 'none', padding: '0 50px' }}
                            size='large'
                        >
                            {t("inner-fcc.single")}
                        </Button>
                    </Popover> */}
          <Select
            placeholder={t("all")}
            style={{ width: 300, marginLeft: "20px" }}
            allowClear
            value={productType}
            onChange={(e) => setProductType(e)}
            options={[
              { value: "chemical", label: t("navbar.chemicals") },
              { value: "mineral", label: t("navbar.minerals") },
            ]}
            size="large"
          />
        </div>
      </div>

      {isLoading ? <Loader /> : null}
      {!_.isEmpty(allLists) && (
        <div className="balance">
          <span>
            {t("balans")}{" "}
            {balance?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            {t("invoice.sum")}
          </span>
        </div>
      )}

      {/* <div className="invoice-cards">
                <div className="cards-wrapper">
                    {allLists?.map((app, idx) => {
                        return (
                            <div style={{ position: 'relative' }}>
                                {app?.is_payment_pending && (
                                    <span
                                        style={{ position: 'absolute', zIndex: '1', top: '5px', right: '5px' }}
                                        className="pending"
                                        onClick={() => {
                                            setAppId(app.id);
                                            setConfirmModal(true);
                                        }}
                                    >
                                        <AiOutlineExclamationCircle size={28} style={{ color: 'red', cursor: 'pointer' }} />
                                    </span>
                                )}
                                <div
                                    className="invoice-card wow fadeInLeft"
                                    data-wow-duration={`${0.1 * (idx + 1)}s`}
                                    data-wow-delay={`${0.1 * (idx + 1)}s`}
                                    key={idx}
                                    onClick={() => {
                                        if (app?.is_cancelled) {
                                            if (app?.product_type === "Chemical") {
                                                setIsOpenChemical(true);
                                                setAppData(app);
                                            } else if (app?.product_type === "Mineral") {
                                                setIsOpenMineral(true);
                                                setAppData(app);
                                            }
                                        }
                                    }}
                                >
                                    <div
                                        className={
                                            app.is_payment_pending ? 'card-blue' : (app?.is_cancelled === true ? "card-red" : "card-wrapper")
                                        }
                                    >
                                        <span className="invoice-name">
                                            {app?.company?.name}
                                            <br />
                                        </span>
                                        <div className="card-amount">
                                            {app?.invoice?.price == null ? (
                                                "***"
                                            ) : (
                                                <>
                                                    <span>
                                                        {app?.invoice?.price
                                                            ?.toString()
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </span>
                                                    <span>{t("invoice.sum")}</span>
                                                </>
                                            )}
                                        </div>
                                        <div className="card-bottom" style={{ marginBottom: '5px' }}>
                                            <div className="invoice-num">
                                                <span>ID</span>
                                                <span>
                                                    {app?.unique_id}
                                                </span>
                                            </div>
                                            <div className="invoice-date">
                                                <span>{t("invoice.date")}</span>
                                                <span>
                                                    {app?.created_at
                                                        ? moment(app?.created_at).format("DD-MM-YYYY")
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card-bottom" style={{ marginBottom: '5px' }}>
                                            <div className="invoice-num">
                                                <span>Mahsulot nomi</span>
                                                <span>
                                                    {app?.product_register_apps && app?.product_register_apps[0] ? app?.product_register_apps[0].name : '-'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card-bottom">
                                            <div className="invoice-num">
                                                <span>Holati</span>
                                                <span>
                                                    {app?.register_step}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div> */}

      {isLoading ? <Loader /> : null}
      <div className="table-wrap" style={{ marginTop: "20px" }}>
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th>{t("name-company")}</th>
              <th>{t("tr-ariza")}</th>
              <th>{t("agro.productName")}</th>
              <th>{t("common.category")}</th>
              <th>{t("invoice.date")}</th>
              <th>{t("inner-fcc.sos-ot")}</th>
              <th>{t("operations")}</th>
            </tr>
          </thead>
          <tbody>
            {allLists?.map((item, index) => {
              return (
                <TableRow
                  item={item}
                  index={index}
                  key={index}
                  setIsOpenChemical={setIsOpenChemical}
                  setIsOpenMineral={setIsOpenMineral}
                  setAppData={setAppData}
                  setAppId={setAppId}
                  setConfirmModal={setConfirmModal}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {appId && (
        <ConfirmModal
          open={confirmModal}
          appId={appId}
          setOpen={handleConfirmOpen}
        />
      )}
      {_.isEmpty(allLists) && !isLoading && (
        <div className="empty-table">
          <Empty
            imageStyle={{
              height: 200,
            }}
            description={<span>{t("balance.noData")}</span>}
          />
        </div>
      )}
    </div>
  );
};

export default Guvohnoma;
