import React, { useEffect, useState } from "react";
import "../comparison/table.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import _ from "lodash";
import { requestPublic } from "../../helpers/createRequest";
import queryString from "query-string";
import Loader from "../../components/loader/Loader";
import { withRouter } from "react-router-dom";

const textRight = {
  textAlign: "right",
};

const textLeft = {
  textAlign: "left",
};

const textCenter = {
  textAlign: "center",
};

const nowrap = {
  whiteSpace: "nowrap",
};

const fontBold = {
  fontWeight: 600,
};

const borderNone = {
  border: "none",
};

const withBorder = {
  border: "1px solid #000",
  borderCollapse: "collapse",
  verticalAlign: "middle",
  textAlign: "center",
  fontSize: "12px",
  lineHeight: "13px",
};

const ActComparison = (props) => {
  const { t } = useTranslation();
  const [actItem, setActItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const queryParams = queryString.parse(window.location.search);
  const token = queryParams.token;

  useEffect(() => {
    if (token) {
      fetchData();
      props.history.push({ search: queryString.stringify({}) });
    } else {
      setIsLoading(false);
      toast.error("Xatolik!");
      props.history.push({ search: queryString.stringify({}) });
    }
  }, []);

  const fetchData = async () => {
    await requestPublic
      .get(`invoice/api/invoice-reconciliation?token=${token}`)
      .then((res) => {
        if (!_.isEmpty(res.data.operations)) {
          setActItem(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.warning("Natija topilmadi!");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status == 451) {
          toast.error("Ushbu oferta raqamiga joriy xizmat ko'rsatilmaydi!");
        } else {
          toast.error("Xatolik!");
          if (e.response.data?.comment) {
            toast.error(e.response.data.comment);
          }
        }
      });
  };

  let nf = new Intl.NumberFormat("fr-FR");

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        actItem && (
          <div className="row">
            <div className="invoice-container">
              <div className="note">
                <AiOutlineExclamationCircle className="icon" />
                <p>{t("navbar.test1")}</p>
              </div>
              <div className="card card-body" id="printableArea">
                <div className="down-btn1">
                  {/* <ActDownloadIcon /> */}
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn-icon"
                    table="table-to-xls"
                    filename={
                      actItem?.invoice_number +
                      "__" +
                      actItem?.beginning_of_interval +
                      "_" +
                      actItem?.end_of_interval
                    }
                    sheet="1"
                    buttonText={""}
                    // type="primary"
                  />
                  <span className="tooltip">{t("invoice.download")}</span>
                </div>
                <div className="invoice-info">
                  <table id="table-to-xls">
                    <tbody>
                      {/* heading */}
                      <tr className="border-none">
                        <th colSpan={7}>Solishtirma dalolatnoma</th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}></th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>
                          Biz, quyida imzo chekuvchilar, {actItem?.agency_name}{" "}
                          bosh hisobchisi
                        </th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>
                          ___________________________________________________________________
                          va
                        </th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>{actItem?.customer_name} direktori</th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>
                          {actItem?.customer_director ||
                            "___________________________________________________________________"}
                        </th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>
                          {actItem?.invoice_number}-sonli oferta shartnomasi{" "}
                          {"(" + actItem?.invoice_service_type + ")"} bo'yicha
                        </th>
                      </tr>
                      <tr className="border-none">
                        <th colSpan={7}>
                          tuzdik ushbu dalolatnomani shu haqidaki{" "}
                          {actItem?.beginning_of_interval}-
                          {actItem?.end_of_interval} yil davri uchun o'zaro
                          hisob-kitoblar quyidagicha:
                        </th>
                      </tr>
                      <tr className="border-none main-act">
                        <th colSpan={7}></th>
                      </tr>
                      {/* table head */}
                      <tr>
                        <th style={withBorder}>№</th>
                        <th style={withBorder}>Sana</th>
                        <th style={withBorder}>Xo`jalik operatsiyasi</th>
                        <th style={withBorder} colSpan={2}>
                          O'zbekiston Respublikasi o'simliklar karantini va
                          himoyasi agentligi{" "}
                        </th>
                        <th style={withBorder} colSpan={2}>
                          {actItem?.customer_name}
                        </th>
                      </tr>
                      {/* main table body */}
                      <tr>
                        <td style={withBorder} />
                        <td style={withBorder} />
                        <td style={withBorder} />
                        <td style={withBorder}>Debet</td>
                        <td style={withBorder}>Kredit</td>
                        <td style={withBorder} className="nowrap">
                          Debet
                        </td>
                        <td style={withBorder} className="nowrap">
                          Kredit
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #000",
                            borderCollapse: "collapse",
                            verticalAlign: "middle",
                            textAlign: "right",
                            fontSize: "12px",
                            lineHeight: "13px",
                          }}
                          colSpan={3}
                          className="font-bold text-right"
                        >
                          {actItem?.beginning_of_interval} yil holatiga qoldiq
                        </th>
                        <td style={withBorder} className="nowrap">
                          {actItem?.balance_in_beginning_of_interval < 0 &&
                            actItem?.balance_in_beginning_of_interval.toLocaleString(
                              "fr-FR"
                            )}
                        </td>
                        <td style={withBorder} className="nowrap">
                          {actItem?.balance_in_beginning_of_interval > 0 &&
                            actItem?.balance_in_beginning_of_interval.toLocaleString(
                              "fr-FR"
                            )}
                        </td>
                        <td style={withBorder} className="nowrap">
                          {actItem?.balance_in_beginning_of_interval > 0 &&
                            actItem?.balance_in_beginning_of_interval.toLocaleString(
                              "fr-FR"
                            )}
                        </td>
                        <td style={withBorder} className="nowrap">
                          {actItem?.balance_in_beginning_of_interval < 0 &&
                            actItem?.balance_in_beginning_of_interval.toLocaleString(
                              "fr-FR"
                            )}
                        </td>
                      </tr>
                      {actItem?.operations
                        ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((item, idx) => (
                          <tr key={idx}>
                            <td style={withBorder} className="nowrap">
                              {idx + 1}
                            </td>
                            <td style={withBorder}>{item.date}</td>
                            <td style={withBorder}>{item.description}</td>
                            <td style={withBorder} className="nowrap">
                              {item?.debet_for_quarantine.toLocaleString(
                                "fr-FR"
                              ) || ""}
                            </td>
                            <td style={withBorder} className="nowrap">
                              {item?.kredit_for_quarantine.toLocaleString(
                                "fr-FR"
                              ) || ""}
                            </td>
                            <td style={withBorder} className="nowrap">
                              {item?.debet_for_applicant.toLocaleString(
                                "fr-FR"
                              ) || ""}
                            </td>
                            <td style={withBorder} className="nowrap">
                              {item?.kredit_for_applicant.toLocaleString(
                                "fr-FR"
                              ) || ""}
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <th
                          style={{
                            border: "1px solid #000",
                            borderCollapse: "collapse",
                            verticalAlign: "middle",
                            textAlign: "right",
                            fontSize: "12px",
                            lineHeight: "13px",
                          }}
                          colSpan={3}
                          className="font-bold text-right"
                        >
                          Jami tovar (ish, xizmatlar) aylanmasi
                        </th>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.total_debet_for_quarantine.toLocaleString(
                            "fr-FR"
                          )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.total_kredit_for_quarantine.toLocaleString(
                            "fr-FR"
                          )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.total_debet_for_applicant.toLocaleString(
                            "fr-FR"
                          )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.total_kredit_for_applicant.toLocaleString(
                            "fr-FR"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #000",
                            borderCollapse: "collapse",
                            verticalAlign: "middle",
                            textAlign: "right",
                            fontSize: "12px",
                            lineHeight: "13px",
                          }}
                          colSpan={3}
                          className="font-bold text-right"
                        >
                          {actItem?.end_of_interval} yil holatiga qoldiq
                        </th>
                        <td style={withBorder} className="nowrap">
                          {actItem.balance_in_end_of_interval < 0 &&
                            nf.format(
                              Number(
                                actItem?.balance_in_end_of_interval
                              ).toFixed(2)
                            )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.balance_in_end_of_interval > 0 &&
                            nf.format(
                              Number(
                                actItem?.balance_in_end_of_interval
                              ).toFixed(2)
                            )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.balance_in_end_of_interval > 0 &&
                            nf.format(
                              Number(
                                actItem?.balance_in_end_of_interval
                              ).toFixed(2)
                            )}
                        </td>
                        <td style={withBorder} className="nowrap font-bold">
                          {actItem?.balance_in_end_of_interval < 0 &&
                            nf.format(
                              Number(
                                actItem?.balance_in_end_of_interval
                              ).toFixed(2)
                            )}
                        </td>
                      </tr>
                      <tr className="border-none">
                        <td colSpan={7}></td>
                      </tr>
                      <tr className="border-none">
                        <td
                          style={textCenter}
                          colSpan={7}
                          className="font-bold"
                        >
                          Ushbu tuzilgan solishtirma dalolatnomaga asosan{" "}
                          {actItem?.end_of_interval} yil holatiga{" "}
                          {nf.format(
                            Number(actItem?.balance_in_end_of_interval).toFixed(
                              2
                            )
                          )}{" "}
                          сўм{" "}
                          {(actItem?.balance_in_end_of_interval == 0 && "") ||
                            (actItem?.balance_in_end_of_interval < 0 &&
                              "O'zbekiston Respublikasi o'simliklar karantini va himoyasi agentligi foydasiga") ||
                            (actItem?.balance_in_end_of_interval > 0 &&
                              actItem?.customer_name + " foydasiga")}
                        </td>
                      </tr>
                      <tr className="border-none">
                        <td colSpan={7}> </td>
                      </tr>
                      <tr className="border-none">
                        <td colSpan={7}> </td>
                      </tr>
                      <tr className="border-none">
                        <td colSpan={7}> </td>
                      </tr>
                      <tr className="border-none">
                        <td style={textCenter} colSpan={3}>
                          O'zbekiston Respublikasi o'simliklar karantini va
                          himoyasi agentligi
                        </td>
                        <td style={textCenter} colSpan={4}>
                          {actItem?.customer_name}
                        </td>
                      </tr>
                      <tr className="border-none">
                        <td style={textCenter} colSpan={3}>
                          __________________________________
                        </td>
                        <td style={textCenter} colSpan={4}>
                          __________________________________
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="editor" />
            </div>
          </div>
        )
      )}

      {/* ) : null} */}
    </>
  );
};

export default withRouter(ActComparison);
