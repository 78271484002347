import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { setLocalTime } from "../../utils";
import { IoIosInformationCircleOutline } from "react-icons/io";
import ProcessModal from "./modals/ProcessModal";
import { useSelector } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { ModalButton } from "./components/layout/button";
import { DeleteAppButton } from "./components/delete-app";
import { Space } from "antd";

const TableRow = (props) => {
  const { t } = useTranslation();
  const {
    item,
    index,
    setIsOpenChemical,
    setIsOpenMineral,
    setAppData,
    setAppId,
    setConfirmModal,
  } = props;

  const lang = useSelector((state) => state.language.lang);
  const [openAbout, setOpenAbout] = useState(false);

  return (
    <>
      <tr key={index}>
        <td>{index + 1}</td>
        <td style={{ maxWidth: "350px" }}>{item?.company?.name}</td>
        <td>{item?.unique_id}</td>
        <td style={{ maxWidth: "350px" }}>
          {item?.product_register_apps
            ? item?.product_register_apps[0]?.name
            : null}
        </td>
        <td>
          {item?.product_type === "Chemical"
            ? t("navbar.chemicals")
            : t("navbar.minerals")}
        </td>
        <td>{setLocalTime(item?.created_at)}</td>
        <td
          style={{ maxWidth: "300px", color: "#036323", cursor: "pointer" }}
          onClick={() => setOpenAbout(true)}
        >
          {!item?.is_active &&
          !!item?.cancel_reason &&
          !item?.certificates?.length ? (
            <IoCloseCircleOutline size={24} color="red" />
          ) : null}

          {item?.is_active && item?.is_cancelled ? (
            <IoIosInformationCircleOutline size={24} color="orange" />
          ) : null}

          {!item?.is_cancelled && item?.status
            ? item?.status[item?.status?.length - 1][`status_${lang}`]
            : null}
        </td>
        <td>
          <Space size="middle">
            {item?.is_active &&
              item?.is_cancelled &&
              !item?.is_payment_pending && <ModalButton data={item} />}
            {item?.is_active && <DeleteAppButton rowId={item.id} />}
          </Space>
          {/* CONFIRM PAYMENT MODAL */}
          {/* {
                        item?.is_active && item?.is_payment_pending ? (
                            <p
                                style={{ cursor: 'pointer', color: "#0000ff", fontWeight: "600", margin: '0' }}
                                className={"button-active"}
                                // type="primary"
                                onClick={() => {
                                    setAppId(item.id);
                                    setConfirmModal(true);
                                }}
                            >{t('pay')}</p>
                        ) : null
                    } */}

          {item?.certificates ? (
            <a
              href={item?.certificates ? item?.certificates[0]?.link : "#"}
              target="_blank"
              rel="noreferrer"
            >
              <p
                className={"button-active"}
                style={{
                  cursor: "pointer",
                  color: "#0000ff",
                  fontWeight: "600",
                  margin: "0",
                }}
              >
                {t("see-guvohnoma")}
              </p>
            </a>
          ) : null}
        </td>
      </tr>
      <ProcessModal
        openAbout={openAbout}
        setOpenAbout={setOpenAbout}
        steps={item?.status}
        item={item}
      />
    </>
  );
};

export default TableRow;
