import { Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { requestReg } from "../../../helpers/createRequest";
import { toast } from "react-toastify";
import { antIcon } from "../../invoice/Invoice";
import { makeOptions } from "../../../utils";
import { useSelector } from "react-redux";

const AddManModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.language.lang);

  const [isBtnActive, setIsBtnActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [lnumber, setLnumber] = useState("");
  const [ltype, setLtype] = useState("");
  const [valPeriod, setValPeriod] = useState("");
  const [address, setAddress] = useState("");
  const [inn, setInn] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");

  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(null);

  const getCountries = async () => {
    try {
      const res = await requestReg.get(`/item/countries`);
      setCountries(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkBtnActive = () => {
    if (
      name &&
      lnumber &&
      ltype &&
      valPeriod &&
      address &&
      inn &&
      fax &&
      email &&
      fullname &&
      phone &&
      website &&
      countryId
    ) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  };

  useEffect(() => {
    checkBtnActive();

    // eslint-disable-next-line
  }, [
    name,
    lnumber,
    ltype,
    valPeriod,
    address,
    inn,
    fax,
    email,
    fullname,
    phone,
    website,
    countryId,
  ]);

  const fetchData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await requestReg
      .post(`product/manufacturers`, {
        name: name,
        phone,
        website,
        l_number: lnumber,
        l_type: ltype,
        l_val_period: valPeriod,
        legal_address: address,
        inn: inn,
        fax: fax,
        email: email,
        owner_fullname: fullname,
        country: countryId,
      })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        toast.success("Ishlab chiqaruvchi muvaffaqiyatli qo'shildi!");
        setName("");
        setPhone("");
        setWebsite("");
        setLnumber("");
        setLtype("");
        setValPeriod("");
        setAddress("");
        setInn("");
        setFax("");
        setEmail("");
        setFullname("");
        setOpen(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const allErrors = Object.values(error?.response?.data);
        toast.error(
          <div style={{ display: "flex", flexDirection: "column" }}>
            {allErrors?.map((item, index) => {
              return (
                <div key={index} style={{ fontSize: "14px", padding: "5px 0" }}>
                  <span style={{ color: "red" }}>*</span>{" "}
                  {item ? item[0] : null}
                </div>
              );
            })}
          </div>,
          {
            closeButton: false,
            autoClose: false,
          }
        );
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Modal
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={875}
      className="add-my-fcc-modal"
    >
      <h1>{t("addMan.createMan")}</h1>
      <form>
        <div className="roow">
          <div>
            <label>{t("addMan.name")}</label>
            <input
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label>{t("addMan.lnumber")}</label>
            <input
              type="text"
              value={lnumber}
              onChange={(e) => setLnumber(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("addMan.phone")}</label>
            <input
              type="text"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div>
            <label>{t("addMan.website")}</label>
            <input
              type="text"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("addMan.ltype")}</label>
            <input
              type="text"
              value={ltype}
              required
              onChange={(e) => setLtype(e.target.value)}
            />
          </div>
          <div>
            <label>{t("addMan.valPeriod")}</label>
            <input
              type="date"
              value={valPeriod}
              onChange={(e) => setValPeriod(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("addMan.address")}</label>
            <input
              type="text"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div>
            <label>{t("addMan.inn")}</label>
            <input
              type="text"
              value={inn}
              onChange={(e) => setInn(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("addMan.fax")}</label>
            <input
              type="text"
              value={fax}
              required
              onChange={(e) => setFax(e.target.value)}
            />
          </div>
          <div>
            <label>{t("addMan.email")}</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="roow">
          <div>
            <label>{t("addMan.owner")}</label>
            <input
              type="text"
              value={fullname}
              required
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div>
            <label>{t("common.country")}</label>
            <Select
              options={makeOptions(
                countries,
                lang === "uz" ? "name_uz_l" : `name_${lang}`
              )}
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={countryId}
              size="large"
              style={{ height: "46px", boxShadow: "none", outline: "none" }}
              onChange={(e) => setCountryId(e)}
            />
          </div>
        </div>
        <div className="btn-wrap compare-btn">
          <button
            type="submit"
            className={isBtnActive ? "button-active" : ""}
            disabled={!isBtnActive}
            onClick={(e) => fetchData(e)}
          >
            {isLoading ? <Spin indicator={antIcon} /> : t("inner-fcc.send")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddManModal;
