import Cookies from 'js-cookie'
import { LANGS } from '../types/Types'
const initialLang = Cookies.get('i18next') || 'uz'

const initialState = {
    lang: initialLang
}

const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANGS:
            return {
                ...state,
                lang: action.payload
            }
        default:
            return state
    }
}

export default langReducer