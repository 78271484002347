import React from "react";
import { Col, Divider, Row } from "antd";
import { PreparationForms } from "./components/preparation";
import { CompanyForms } from "../company/forms";
import { PreparationTwoForms } from "./components/preparationTwo";
export const Chemical = ({ myRef }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <CompanyForms />
      </Col>
      <Divider />
      <Col span={24}>
        <PreparationForms />
      </Col>
      <Col span={24}>
        <PreparationTwoForms myRef={myRef} />
      </Col>
    </Row>
  );
};
