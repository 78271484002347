import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { getCrops, getShapes } from "../../../../../../apis/guvohnoma";
import { REACT_QUERY_KEYS } from "../../../../../../constants/reactQueryKeys";

export const useMyform = () => {
  const { control, watch } = useFormContext();

  const props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };
  const lang_props = {
    control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
      pattern:
        watch("application.language") === "UZ"
          ? {
              value: /^[^\u0400-\u04FF]+$/,
              message:
                "Iltimos faqat lotin  alifbolari yordamida ma'lumot kiriting",
            }
          : {
              value: /^[ЁёА-я0-9!@#$&()%^&+_[|/?=.,"°'{}`* -\]]+$/,
              message:
                "Пожалуйста, вводите информацию, используя только кириллицу",
            },
    },
  };

  const { data: crops, isLoading: cropLoading } = useQuery({
    queryFn: getCrops,
    queryKey: [REACT_QUERY_KEYS.MINERAL_CROPS],
    select: (res) => {
      return res.data.data?.map((pr) => ({
        value: pr.id,
        label:
          watch("application.language") === "UZ" ? pr.name_uz_l : pr.name_ru,
      }));
    },
  });
  const { data: shapes, isLoading: shapeLoading } = useQuery({
    queryFn: getShapes,
    queryKey: [REACT_QUERY_KEYS.MINERAL_SHAPES],
    select: (res) => {
      return res.data.data?.map((pr) => ({
        value: pr.id,
        label:
          watch("application.language") === "UZ" ? pr.name_uz_l : pr.name_ru,
      }));
    },
  });

  return {
    props,
    lang_props,
    crops,
    cropLoading,
    shapes,
    shapeLoading,
    isUz: watch("application.language") === "UZ",
  };
};
