import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WarningIcon from "../../../assets/icons/warningIcon.svg"
import { toast } from 'react-toastify'

const Warning = (props) => {
    const { setOpenWarningModal, openWarningModal } = props

    const { t } = useTranslation()

    const [isBtn, setIsBtn] = useState(false)
    const { lang: lan } = useSelector((state) => state.language)

    const closeWarning = () => {
        if (isBtn) {
            setOpenWarningModal(false)
        } else {
            toast.warning("Iltimos, ogohlantirish bilan tanishing!")
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsBtn(true)
        }, [5000])
    }, [])

    return (
        <Modal
            open={openWarningModal}
            onCancel={() => setOpenWarningModal(false)}
            centered
            width={780}
            className="add-fcc-modal"
            footer={null}
            title={null}
        >
            <div className="langs-outer">
                <CountdownCircleTimer
                    isPlaying
                    duration={5}
                    isGrowing
                    colors="#036323"
                    size={40}
                    strokeWidth={6}
                >
                    {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
            </div>

            <h1 className="warning">
                <img src={WarningIcon} alt='' />
            </h1>

            <div className="modal-inner warning">
                <div className="warning-wrap common">
                    <p>
                        Қишлоқ хўжалиги экинларида ўсимликнинг ўсув даври (вегетация) бошланиб кетганлиги ҳамда синовларни ўтказишда кечикиш
                        холатлари кузатилиши мумкинлигини эътиборга олиб, 2024 йил 20 апрелдан Аризаларни қабул қилиш тўхтатилганлигини маълум қилади.
                        Маълумот учун янги таҳрирдаги "Кимёлаштириш ва ўсимликларни ҳимоя қилиш воситаларини синовдан ўтказиш ҳамда рўйхатга олиш тартиби тўғрисида"ги
                        2024 йил 6 мартдаги 115-сон қарорида Аризаларни қабул қилиш ҳар йили 1 февралгача этиб белгиланган. Ушбу қарор 2024 йилнинг 8 июнидан кучга киради.
                    </p>
                </div>
            </div>

            <div className="btn-wrap warning">
                <button
                    type="button"
                    className={isBtn ? "button-active btn-red" : "btn-red"}
                    disabled={!isBtn}
                    onClick={closeWarning}
                >
                    {t("common.read")}
                </button>
            </div>
        </Modal>
    )
}

export default Warning