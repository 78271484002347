import { Segmented } from "antd";
import React from "react";
import { useController } from "react-hook-form";

export const FormSegmented = ({ name, onChange, control, ...props }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });
  return (
    <Segmented
      {...props}
      {...field}
      {...fieldState}
      onChange={(e) => {
        field.onChange(e);
        if (onChange) {
          onChange(e);
        }
      }}
      id={name}
      className="my-segmented"
      style={{
        backgroundColor: "#f1f3f5",
        padding: "4px",
      }}
    />
  );
};
