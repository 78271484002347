import React from 'react'
import { useTranslation } from 'react-i18next'
import indev from '../../assets/icons/indev.svg'
import './help.css'

const Help = () => {

    const { t } = useTranslation()

    return (
        <div className='kr-page'>
            <div className='center'>
                <div className='img-wrap'>
                    <img src={indev} alt='' />
                </div>
                <p>{t('navbar.in-dev')}</p>
            </div>
        </div>
    )
}

export default Help